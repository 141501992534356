import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Modal,
  styled
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { frontArrow, backArrow, dot, tick, close } from "./assets";
import { backgroundArrow1 } from "../../email-account-login/src/assets";

// Customizable Area End

import CategoriessubcategoriesController, {
  Props
} from "./CategoriessubcategoriesController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
export const openBox = (
  openBox1: any,
  openBox2: any,
  title: any,
  boxContent: any
) => {
  return (
    <Box style={styles.openBox}>
      <OpenBox
        style={{
          backgroundColor: "grey",
          border: "2px solid #262626",
          background: "#FFF",
          opacity: openBox1 || openBox2 ? 1 : 0,
          margin: 0,
          height: "auto"
        }}
      >
        <Box style={styles.openBoxTitle}>
          <TitleTypography>{title}</TitleTypography>
        </Box>
        <Box style={styles.openBoxContent}>
          <ContentTypography>{boxContent}</ContentTypography>
        </Box>
      </OpenBox>
    </Box>
  );
};
export default class Categoriessubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderHeaderlink = () => {
    if (!this.state.confirmCategory) {
      return (
        <Box sx={styles.headerLinks}>
          <Box sx={{ ...styles.headerLinksContent }} >
            <CustomizableImage id="back" src={this.state.language === "en" ? backArrow : frontArrow} onClick={this.handleBack} />
            <Box
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <Box sx={styles.links}>{this.getContent(this.state.pageTxtData.previous,this.state.pageTxtData.previousArabic)}</Box>
              <Box sx={styles.linksData}>{this.getContent(this.state.pageTxtData.typeOfRole,this.state.pageTxtData.typeOfRoleArabic)}</Box>
            </Box>
          </Box>
          <Box sx={{ ...styles.forwardHeaderLinksContent }}>
            <Box className="chedk31" sx={styles.box8}>
              <Box sx={styles.forwardLinks}>{this.getContent(this.state.pageTxtData.upNext,this.state.pageTxtData.upNextArabic)}</Box>
              <Box sx={styles.linksData}>{this.getContent(this.state.pageTxtData.typeOfCompany,this.state.pageTxtData.typeOfCompanyArabic)}</Box>
            </Box>
            <CustomizableImage src={this.state.language === "en" ? frontArrow : backArrow} onClick={this.handleForward}/>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            padding: "10px"
          }}
        >
          <CustomBackButton variant="contained" onClick={this.handleBack}>
            <CustomBackButtonContent>{this.getContent(this.state.pageTxtData.backToHome,this.state.pageTxtData.backToHomeArabic)}</CustomBackButtonContent>
          </CustomBackButton>
        </Box>
      );
    }
  };
  renderButtonContent = () => {
    if (this.state.confirmCategory) {
      return (
        <ButtonTypography style={styles.buttonContent}>
          {this.getContent(this.state.pageTxtData.stayInformed,this.state.pageTxtData.stayInformedArabic)}
        </ButtonTypography>
      );
    } else {
      return (
        <ButtonTypography data-test-id="next-btn" style={styles.buttonContent}>{this.getContent(this.state.pageTxtData.next,this.state.pageTxtData.nextArabic)}</ButtonTypography>
      );
    }
  };
  renderheading = () => {
    if (!this.state.confirmCategory) {
      return (
        <Box sx={styles.headingBox}>
          <Top_heading>{this.getContent(this.state.pageTxtData.iAmSelling,this.state.pageTxtData.iAmSellingArabic)}</Top_heading>
        </Box>
      );
    }
  };
  renderOpenBoxDotImg = () => {
    if (this.state.openBox && !this.state.confirmCategory) {
      return <DotImg src={dot} />;
    }
  };
  renderDisabled = () => {
    if (this.state.openBox || this.state.openCompanyBox) {
      return false;
    } else {
      return true;
    }
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <Modal open={this.state.opneModal}>
          <Box sx={styles.modal21}>
            <SuccessModalBox>
              <Box sx={styles.modalContainerBox}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    width: "100%"
                  }}
                >
                  <CloseImage
                    src={close}
                    onClick={() => this.setState({ opneModal: false })}
                  />
                </Box>
                <Box sx={styles.modalContainerHeaderBox}>
                  <Box style={styles.modalContainerHeaderContent}>
                    <TickImage src={tick} />
                    <SuccessTypography>{this.getContent(this.state.pageTxtData.success,this.state.pageTxtData.successArabic)}</SuccessTypography>
                  </Box>
                </Box>
                <ModalBox>
                  <ModalTypography>
                    {this.getContent(this.state.pageTxtData.assetsList,this.state.pageTxtData.assetsListArabic)}
                  </ModalTypography>
                </ModalBox>
              </Box>
            </SuccessModalBox>
          </Box>
        </Modal>
        <HeaderBlock />
        <div className="mainlogin">
          <Box className="contain" style={styles.backGroundContain}>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              {this.renderHeaderlink()}
              <Container style={styles.container}>
                <Box style={styles.containerBox}>
                  {this.renderheading()}
                  <Box sx={styles.box18}>
                    <Box
                      sx={{
                        border: this.state.openBox
                          ? "4px solid #5412FC"
                          : "2px solid #8C8C8C",
                        ...(!this.state.openBox ? styles.box19 : styles.box20)
                      }}
                      className="box19"
                      onClick={this.handleBox}
                    >
                      <div>{this.renderOpenBoxDotImg()}</div>
                      <div
                        style={{
                          width: "100%",
                          height:
                            this.state.openBox && !this.state.confirmCategory
                              ? "70%"
                              : "100%",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex"
                        }}
                      >
                        <TypographyCategory
                          style={{
                            color: this.state.openBox
                              ? "rgba(84, 18, 252, 0.80)"
                              : ""
                          }}
                          className="boxText"
                        >
                          {this.getContent(this.state.pageTxtData.assets,this.state.pageTxtData.assetsArabic)}
                        </TypographyCategory>
                      </div>
                    </Box>
                    {!this.state.confirmCategory && (
                      <Box
                        // sx={styles.box19}
                        sx={{
                          border: this.state.openCompanyBox
                            ? "4px solid #5412FC"
                            : "2px solid #8C8C8C",
                          ...(!this.state.openCompanyBox
                            ? styles.box19
                            : styles.box20)
                        }}
                        onClick={this.handleCompanyBox}
                      >
                        {this.state.openCompanyBox && <DotImg src={dot} />}
                        <div
                          style={{
                            width: "100%",
                            height: this.state.openCompanyBox ? "70%" : "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex"
                          }}
                        >
                          <TypographyCategory
                            style={{
                              color: this.state.openCompanyBox
                                ? "rgba(84, 18, 252, 0.80)"
                                : ""
                            }}
                            className="boxText"
                          >
                            {this.getContent(this.state.pageTxtData.company,this.state.pageTxtData.companyArabic)}
                          </TypographyCategory>
                        </div>
                      </Box>
                    )}
                  </Box>
                  {openBox(
                    this.state.openBox,
                    this.state.openCompanyBox,
                    this.state.boxTitle,
                    this.state.boxContent
                  )}
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px"
                    }}
                  >
                    <CustomizeButton
                      disabled={this.renderDisabled()}
                      style={{
                        background:
                          this.state.openCompanyBox || this.state.openBox
                            ? "#10BFB8"
                            : "#8C8C8C"
                      }}
                      variant="contained"
                      onClick={this.confirmSubCategory}
                    >
                      {this.renderButtonContent()}
                    </CustomizeButton>
                  </Box>
                </Box>
              </Container>
            </Box>
          </Box>
        </div>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export const styles = {
  backGroundContain: {
    backgroundImage: `url(${backgroundArrow1})`,
    backgroundSize: "cover",
    backgroundPositionX: "center"
  },
  headerLinks: {
    display: "flex",
    // padding: "10px",
    justifyContent: "space-between",
    marginBottom:'38px'
  },
  headerLinksContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop:'43px',
    marginLeft:'61px',
    gap:'40px',
    [theme.breakpoints.down("xs")]: {
      marginTop:'31px',
      marginLeft:'10px',
      gap:'11px'
    }
    // "&:hover": {
    //   cursor: "pointer"
    // }
  },
  forwardHeaderLinksContent:{
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop:'43px',
    marginRight:'61px',
    gap:'40px',
    [theme.breakpoints.down("xs")]: {
      marginTop:'31px',
      marginRight:'10px',
      gap:'11px'
    }
  },
  container: {
    display: "flex",
    justifyContent: "center"
  },
  containerBox: {
    width: "100%",
    // marginTop: "2%",
    marginBottom: "60px"
  },
  headingBox: {
    display: "flex",
    justifyContent: "center"
  },
  headingTitle: {
    color: "#5412FC",
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 300,
    fontFamily: " Montserrat"
  },
  openBox: {
    display: "flex",
    justifyContent: "center"
  },
  openBoxConatiner: {
    borderRadius: "0px 20px 20px 20px",
    width: "60%",
    background: "#FFF"
  },
  openBoxTitle: {
    display: "flex",
    justifyContent: "center",
    padding: "2%"
  },
  openBoxContent: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "1%",
    paddingBottom: "4%",
    paddingLeft: "4%",
    paddingRight: "4%"
  },
  buttonbox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "7%"
  },
  buttonContent: {
    color: "white",
    fontSize: "24px",
    fontFamily: "Montserrat",
    fontWeight: 700
  },
  modal21: {
    position: "absolute" as "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "20px",
    backgroud: "#fff",
    boxShadow: 24
  },
  modalContainerBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "100%"
  },
  modalContainerHeaderBox: {
    display: "flex",
    alignItems: "center"
  },
  modalContainerHeaderContent: {
    display: "flex",
    gap: "25px",
    alignItems: "center"
  },
  box19: {
    width: "200px",
    height: "200px",
    borderRadius: "20px",
    background: "#FFF",
    transition: "border-color 0.3s, box-shadow 0.3s",
    cursor: "pointer",
    "& *": {
      cursor: "pointer",
    },
    "&:hover": {
      boxShadow: "0px 6px 8px 1px rgba(84, 18, 252, 0.50)",
      border: "2px solid #5412FC",
      "& div":{
        cursor:'pointer'
      },
      "& .boxText": {
        color: "rgba(84, 18, 252, 0.80)",
        fontWeight: 700
      }
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
      height: "114px !important",
      width: "114px !important"
    }
  },
  box20: {
    width: "200px",
    height: "200px",
    borderRadius: "20px",
    background: "#FFF",
    cursor: "pointer",
    "& *": {
      cursor: "pointer",
    },
    "&:hover": {
      boxShadow: "none !important",
      border: "4px solid #5412FC",
      "& div":{
        cursor:'pointer'
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
      height: "114px !important",
      width: "114px !important"
    }
  },
  box18: {
    display: "flex",
    justifyContent: "center",
    gap: "160px",
    // marginTop: "3%",
    [theme.breakpoints.down("xs")]: {
      gap: "40px"
    }
  },

  links: {
    color: "#262626",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
      fontSize: "10px !important"
    }
  },
  forwardLinks: {
    color: "#262626",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    textAlign: "right",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
      fontSize: "10px"
    }
  },
  linksData: {
    color: "#262626",
    fontFamily: "Montserrat",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important",
      fontSize: "10px !important"
    }
  },
  box8: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px !important"
    }
  }
};
export const Top_heading = styled(Typography)({
  color: "#5412FC",
  textAlign: "center",
  fontFamily: "Montserrat",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 700,
  marginBottom: "70px",
  "@media(max-width: 600px)": {
    fontSize: "16px !important",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "40px !important"
  }
});
export const TypographyCategory = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 600,
  "@media(max-width: 600px)": {
    fontSize: "18px !important"
  }
});
export const CustomizeButton = styled(Button)({
  borderRadius: "12px",
  width: "300px",
  height: "64px",
  "@media(max-width: 600px)": {
    width: "156px !important",
    borderRadius: "5px !important",
    height: "unset !important"
  },
  "&:hover":{
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
  }
});
export const ButtonTypography = styled(Button)({
  color: "white",
  fontSize: "24px",
  fontFamily: "Montserrat",
  fontWeight: 700,
  textTransform: "none !important" as "none",
  backgroundColor: "rgba(0,0,0,0) !important",
  [theme.breakpoints.down("xs")]: {
    fontSize: "12px !important"
  }
});
export const TitleTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontWeight: 700,
  fontStyle: "normal",
  color: "#262626"
});
export const ContentTypography = styled(Typography)({
  fontFamily: "Montserrat",
  fontSize: "14px",
  fontWeight: 400,
  fontStyle: "normal",
  color: "#262626",
  textAlign: "center"
});
export const CustomizableImage = styled("img")({
  width: "50px",
  heigth: "50px",
  "&:hover": {
      cursor: "pointer"
    },
  // padding: "25px",
  "@media(max-width: 600px)": {
    height: "30px !important",
    width: "30px !important",
    padding: "10px !important"
  }
});
export const DotImg = styled("img")({
  width: "24px",
  height: "24px",
  position: "relative",
  top: "20px",
  left: "150px",
  right:"150px",
  "@media(max-width: 600px)": {
    height: "14px !important",
    width: "14px !important",
    top: "10px !important",
    left: "90px !important"
  }
});
const ModalTypography = styled(Typography)({
  color: "#262626",
  textAlign: "center",
  fontFamily: "Montserrat",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  marginTop: "3%",
  "@media(max-width: 600px)": {
    fontSize: "14px !important",
    color: "#000 !important"
  }
});
const SuccessTypography = styled(Typography)({
  color: "#262626",
  fontSize: "30px",
  fontFamily: "Montserrat",
  fontWeight: 700,
  "@media(max-width: 600px)": {
    fontSize: "24px !important",
    color: "#000 !important"
  }
});
const SuccessModalBox = styled(Box)({
  width: "800px",
  height: "320px",
  "@media(max-width: 600px)": {
    width: "331px !important"
  },
  "@media(max-width: 400px)": {
    width: "271px !important"
  }
});
const CustomBackButton = styled(Button)({
  borderRadius: "12px",
  background: "#8C8C8C",
  width: "224px",
  height: "48px",
  padding: "4px 4px !important",
  "@media(max-width: 600px)": {
    width: "104 !important",
    height: "25px !important",
    borderRadius: "5px !important"
  }
});
const CustomBackButtonContent = styled("span")({
  color: "#fff",
  fontFamily: "Montserrat",
  fontSize: "18px",
  fontWeight: 700,
  textTransform: "none !important" as "none",
  "@media(max-width: 600px)": {
    fontSize: "8px !important"
  }
});
export const OpenBox = styled(Box)({
  borderRadius: "20px",
  width: "563px",
  height: "166px",
  marginTop: "30px",
  "@media(max-width: 600px)": {
    width: "75% !important",
    borderRadius: "10px !important",
    height: "unset !important",
    marginTop: "31px !important"
  },
  "@media(max-width: 400px)": {
    width: "80% !important",
    borderRadius: "10px !important",
    height: "unset !important",
    marginTop: "31px !important"
  }
});
const CloseImage = styled("img")({
  paddingRight: "4%",
  cursor: "pointer",
});
const TickImage = styled("img")({});
const ModalBox = styled(Box)({
  width: "80%",
  "@media(max-width: 600px)": {
    width: "90% !important"
  }
});
// Customizable Area End
