import React from "react";
// Customizable Area Start
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
  ThemeProvider
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import FooterBlock from "../../../components/src/FooterBlock.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import BuyerProfileController, { Props, configJSON } from "./BuyerProfileController.web";
import {  uploadIcon, calendarIcon } from "./assets";
import { AccordionSelect } from "./AccordionSelect";
import { conditionSort, isDisabledSubmit } from "../../../components/src/Helper";

let modifiedTheme: Theme = createTheme();
modifiedTheme = createTheme({
  overrides: {
    MuiSelect: {
      root: {
        fontSize: "20px",
        lineHeight: "24px",
        fontFamily: "Montserrat, sans-serif !important",
        padding: "11px 18px",
        border: "1px solid #8C8C8C",
        width: "100%",
        [modifiedTheme.breakpoints.down("sm")]: {
          fontSize: "10px",
          lineHeight: "20px",
          padding: "5px 12px"
        }
      },
      outlined: {
        borderRadius: "12px",
        [modifiedTheme.breakpoints.down("sm")]: {
          borderRadius: "8px"
        }
      },
      select: {
        "&:focus": {
          borderRadius: "12px",
          [modifiedTheme.breakpoints.down("sm")]: {
            borderRadius: "8px"
          }
        }
      },
      icon: {
        
      }
    },
    MuiInputBase: {
      root: {
        marginLeft: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "12px",
        [modifiedTheme.breakpoints.down("sm")]: {
          borderRadius: "8px"
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: "20px",
        fontFamily: "Montserrat, sans-serif !important",
        [modifiedTheme.breakpoints.down("sm")]: {
          fontSize: "10px"
        }
      }
    }
  }
});
// Customizable Area End

export default class BuyerProfile extends BuyerProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  GenField = (fieldLabel: string, inputName: string, inputValue: string, errorAry: any, requiredField: boolean) => {
    const { classes } = this.props;
    return (
      <div className={classes.inputWrapper}>
        <label htmlFor={inputName}>
          {fieldLabel}{" "}
          {requiredField && <span className={classes.requiredAsterisk}>*</span>}
        </label>
        <input
          data-test={inputName}
          value={inputValue}
          onChange={this.handleInputChange}
          type="text"
          id={inputName}
          name={inputName}
          required={requiredField}
        />
        <div className={classes.AgeErrorMess}>{errorAry.length > 0 && (errorAry)}</div>
      </div>
    )
  }


  GenInfo = (fieldLabel: string, inputName: string, inputValue: string | undefined) => {
    const { classes } = this.props;
    return (
      <div className={classes.inputWrapper}>
        <label htmlFor={inputName}>
          {fieldLabel}{" "}
        </label>
          
        <div style={styles.infoStyle}>{inputValue}</div>
        
      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const today = new Date();
    
    const { classes, sellerType } = this.props;
    const {
      firstName,
      lastName,
      dateOfBirth,
      linkedinLink,
      aboutYourself,
      numberAcquisitionClosed,
      numberProjectedAnnualAquisitions,
      selectedDocumentFile,
      selectedCountry,
      selectedCity,
      getDocumentURL
    } = this.state;

    const genderList = [
      { title: "Male", value: "Male"},
      { title: "Female", value: "Female"},
    ];

    const genderListAr = [
      { value: "Male", title: "ذكر" },
      { value: "Female", title: "أنثى" },
    ];

    const langList = [
      { title: "English", value: "english"},
      { title: "Arabic", value: "arabic"},
    ];

    const langListAr = [
      { title: "الإنجليزية", value: "english" },
      { title: "العربية", value: "arabic" },
    ];

    const mainLangList = conditionSort(this.state.language == 'ar', langListAr, langList);

    const mainGList = conditionSort(this.state.language == 'ar', genderListAr, genderList);
    const preAccreditedBuyerOptions = [
      {title: "I have an annual income of at least SAR 600,000 in each of the last two years", value: "I have an annual income of at least SAR 600,000 in each of the last two years"},
      {title: "I have a minimum of SAR 1 million in investments in securities", value: "I have a minimum of SAR 1 million in investments in securities"},
      {title: "We are a company that has a total assets of at least SAR 10 million", value: "We are a company that has a total assets of at least SAR 10 million"},
      {title: "I have a net worth of at least SAR 5 million", value: "I have a net worth of at least SAR 5 million"},
      {title: "I have a net worth of at least SAR 3 million", value: "I have a net worth of at least SAR 3 million"},
      {title: "I work or have worked for at least three years in the financial sector in a professional position related to investment in securities or mergers and acquisition", value: "I work or have worked for at least three years in the financial sector in a professional position related to investment in securities or mergers and acquisition"},
      {title: "None of the above", value: "None of the above"}
    ];

    const preAccreditedBuyerOptionsAr = [
      {value: "I have an annual income of at least SAR 600,000 in each of the last two years", title: "لديّ دخل سنوي لا يقل عن 600,000 ريال سعودي في كل سنة من السنتين الأخيرتين"},
      {value: "I have a minimum of SAR 1 million in investments in securities", title: "لدي استثمارات في الأوراق المالية لا تقل عن مليون ريال سعودي"},
      {value: "We are a company that has a total assets of at least SAR 10 million", title: "نحن شركة لا يقل إجمالي أصولها عن 10 مليون ريال سعودي"},
      {value: "I have a net worth of at least SAR 5 million", title: "لديّ صافي ثروة لا تقل عن 5 ملايين ريال سعودي"},
      {value: "I have a net worth of at least SAR 3 million", title: "لديّ ثروة صافية لا تقل عن 3 ملايين ريال سعودي"},
      {value: "I work or have worked for at least three years in the financial sector in a professional position related to investment in securities or mergers and acquisition", title: "أعمل أو عملت لمدة ثلاث سنوات على الأقل في القطاع المالي في وظيفة مهنية تتعلق بالاستثمار في الأوراق المالية أو الاندماج والاستحواذ"},
      {value: "None of the above", title: "لا شيء مما سبق"},
    ];

    const accreditedBuyerOptions: {title: string; value: string}[] = conditionSort(this.state.language == 'ar', preAccreditedBuyerOptionsAr, preAccreditedBuyerOptions);
   

    const selectedCountryT = this.state.countriesData.find((elemtn) => elemtn.value == selectedCountry);
    return (
      <ThemeProvider theme={modifiedTheme}>
        <HeaderBlock profileImageUrl={this.state.getAvatarURL}/>
        <div className={classes.container}>
          <main className={classes.content}>
            <h2 className={classes.title}>{this.state.pageText.fullProfile}</h2>
            
            {conditionSort(this.state.isEditMode, 
            <form
              className={classes.buyerProfileForm}
              onSubmit={this.editBuyerProfile}
            >
              <div className={classes.avatarUpload}>
                <div className={classes.avatarPreview}>
                  <img
                    id="avatarPreviewURL"
                    src={
                      this.computedAvatarURL() || "https://placehold.co/120x120"
                    }
                    alt=""
                    className={classes.avatarPreviewImg}
                  />
                </div>
                <div className={classes.avatarUploadRight}>
                  <div className={classes.avatarTitle}>{this.state.pageText.profilePicture}</div>
                  <label
                    className={classes.updateAvatarLabel}
                    htmlFor="updateAvatar"
                  >
                    <input
                      hidden
                      id="updateAvatar"
                      type="file"
                      name="avatar"
                      accept="image/*"
                      onChange={this.handleAvatarChange}
                    />
                    {conditionSort(this.state.buyerId, null, <div className={classes.updateAvatarLink}>{this.state.pageText.uploadPhoto}</div>)}
                    
                  </label>
                </div>
              </div>

              <div className={classes.inputWrapperGroup}>
                {this.GenField(this.state.pageText.firstName, 'firstName', firstName, this.state.FirstNameError, true)}
                {this.GenField(this.state.pageText.lastName, 'lastName', lastName, this.state.LastNameError, true)}
              </div>

              <div className={classes.inputWrapperGroup}>
                <div className={classes.inputWrapper}>
                  <label htmlFor="dateOfBirth">
                    {this.state.pageText.dob}{" "}
                    <span className={classes.requiredAsterisk}>*</span>
                  </label>
                  <input
                    value={dateOfBirth}
                    onChange={this.handleInputChange}
                    data-test="dateOfBirth"
                    type="date"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    required
                    max={today.toISOString().split("T")[0]}
                  />
                  <div className={classes.AgeErrorMess}>{this.state.InValidAge.length > 0 ? this.state.InValidAge : ""}</div>
                </div>

                <div className={classes.inputWrapper}>
                  <label htmlFor="gender">
                  {this.state.pageText.gender} <span className={classes.requiredAsterisk}>*</span>
                  </label>
                  
                  <AccordionSelect
                    data-test="gender"
                    selectedData={mainGList.find((obje: {title: string, value: string}) => obje.value == this.state.gender )?.title}
                    optionList={mainGList}
                    onChange={(selectedValue: string) =>
                      this.handleAccordionChange("gender", selectedValue)
                    }
                  />
                </div>
              </div>

              <div className={classes.inputWrapper}>
                <label htmlFor="role">
                  {this.state.pageText.selectRole}{" "}
                  <span className={classes.requiredAsterisk}>*</span>
                </label>

                <AccordionSelect
                  data-test="role"
                  selectedData={this.state.profileUserRoles.find((roleObj) => roleObj.value == this.state.role)?.title}
                  optionList={this.state.profileUserRoles}
                  onChange={(selectedValue: string) =>{
                    this.handleAccordionChange("role", selectedValue)

                  }
                  }
                />
              </div>

              {this.GenField(this.state.pageText.linkedIn, 'linkedinLink', linkedinLink, this.state.IsValidUrlMess, false)}
             
              {!sellerType &&
                <>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="country">
                      {this.state.pageText.selectCountry}{" "}
                      <span className={classes.requiredAsterisk}>*</span>
                    </label>
                    <AccordionSelect
                      data-test="countrySelect"
                      selectedData={selectedCountryT?.title}
                      optionList={this.state.countriesData.map(country => ({ value: country.value, title: country.title, icon: country.icon  }))}
                      onChange={(selectedValue: string) =>
                        this.handleAccordionChange("selectedCountry", selectedValue)
                      }
                    />
                  </div>

                  <div className={classes.inputWrapper}>
                    <label htmlFor="city">
                      {this.state.pageText.selectCity}{" "}
                      <span className={classes.requiredAsterisk}>*</span>
                    </label>
                    <AccordionSelect
                      data-test="citySelect"
                      selectedData={selectedCountryT?.cities.find((element) => element.value == selectedCity)?.title}
                      optionList={selectedCountry
                        ? this.state.countriesData.find(country => country.value === selectedCountry)?.cities.map(city => ({
                          value: city.value,
                          title: city.title,
                        })) || []
                        : []
                      }
                      onChange={(selectedValue: string) =>
                        this.handleAccordionChange("selectedCity", selectedValue)
                      }
                      disabled={!selectedCountry}
                    />
                  </div>
                </>
              }

              {sellerType && <div className={classes.inputWrapper}>
                <label htmlFor="preferred_lang">
                  {this.state.pageText.preferredLang}{" "}
                  <span className={classes.requiredAsterisk}>*</span>
                </label>
                <div className={classes.radioGrpWrapper}>
                  {mainLangList.map((lang: {title: string, value: string}) => (
                    <div key={lang.value} className={classes.radioFieldContainer}>
                      <input
                        checked={this.state.preferred_lang === lang.value}
                        onChange={this.handleInputChange}
                        className={classes.radioStyle}
                        name="preferred_lang"
                        type="radio"
                        value={lang.value}
                      />
                      <label style={styles.contentOne}>{lang.title}</label>
                    </div>
                  ))}
                </div>
              </div>}

              <div className={classes.inputWrapper}>
                <label htmlFor="aboutYourself">
                {this.state.pageText.aboutYourself}{" "}
                  <span className={classes.requiredAsterisk}>*</span>
                </label>
                <div className={classes.textareaWrapper}>
                  <textarea
                    data-test="aboutYourself"
                    value={aboutYourself}
                    onChange={this.handleInputChange}
                    id="aboutYourself"
                    name="aboutYourself"
                    rows={4}
                  />
                </div>
                <div className={classes.AgeErrorMess}>{this.state.AboutYourselfError.length > 0 && (this.state.AboutYourselfError)}</div>

              </div>

              {!sellerType &&
                <>
                  <div className={classes.inputWrapper}>
                    <label htmlFor="numberAcquisitionClosed">
                    {this.state.pageText.noOf}{" "}
                      <span className={classes.requiredAsterisk}>*</span>
                    </label>
                    <input
                      data-test="numberAcquisitionClosed"
                      onChange={this.handleInputChange}
                      value={numberAcquisitionClosed}
                      type="number"
                      id="numberAcquisitionClosed"
                      name="numberAcquisitionClosed"
                      required
                    />
                    <div className={classes.AgeErrorMess}>{this.state.NumberAcquisitionError.length > 0 && (this.state.NumberAcquisitionError)}</div>

                  </div>

                  <div className={classes.inputWrapper}>
                    <label htmlFor="numberProjectedAnnualAquisitions">
                    {this.state.pageText.pro}{" "}
                      <span className={classes.requiredAsterisk}>*</span>
                    </label>
                    <input
                      data-test="numberProjectedAnnualAquisitions"
                      onChange={this.handleInputChange}
                      value={numberProjectedAnnualAquisitions}
                      type="number"
                      id="numberProjectedAnnualAquisitions"
                      name="numberProjectedAnnualAquisitions"
                      required
                      minLength={1}
                      maxLength={6}
                    />
                    <div className={classes.AgeErrorMess}>{this.state.ProjectedAquisitionsError.length > 0 && (this.state.ProjectedAquisitionsError)}</div>

                  </div>

                  <div className={classes.inputWrapper}>
                    <label>
                    {this.state.pageText.ver}{" "}
                      <span className={classes.requiredAsterisk}>*</span>
                      {getDocumentURL && (
                        <div>
                          <button
                            type="button"
                            className={classes.getDocumentURL}
                            onClick={this.handleDownload}
                          >
                            {this.state.pageText.down}
                          </button>
                        </div>
                      )}
                    </label>
                    <label
                      className={classes.verifyDropper}
                      htmlFor="verifyIdentity"
                    >
                      <input
                        type="file"
                        required={!getDocumentURL}
                        id="verifyIdentity"
                        name="verifyIdentity"
                        accept="image/*, application/pdf"
                        hidden
                        onChange={this.handleDocumentFileChange}
                      />
                      <div className={classes.dropperContent}>
                        <img
                          className={classes.uploadIcon}
                          src={uploadIcon}
                          alt="upload icon"
                        />
                        <div className={classes.dropperText}>
                          {selectedDocumentFile ? (
                            <span id="selectedDocumentName">
                              {configJSON.selectFeilds} {selectedDocumentFile.name}
                            </span>
                          ) : (
                            this.state.pageText.upl
                          )}
                        </div>
                      </div>
                    </label>
                  </div>

                  <div className={classes.inputWrapper}>
                    <label htmlFor="accreditedBuyer">
                    {this.state.pageText.acc}
                      <span className={classes.requiredAsterisk}>*</span>
                    </label>
                    <AccordionSelect
                      data-test="accreditedBuyer"
                      selectedData={accreditedBuyerOptions.find((obje: {title: string, value: string}) => obje.value == this.state.accreditedBuyer )?.title}
                      optionList={accreditedBuyerOptions}
                      onChange={(selectedValue: string) =>
                        this.handleAccordionChange("accreditedBuyer", selectedValue)
                      }
                    />
                  </div>
                </>
              }

              {
                conditionSort(this.state.buyerId, null, <div className={classes.formButtons}>
                  <Button
                    disabled={this.isAllClear()}
                    onClick={() => this.clearFrom()}
                    type="button"
                    className={`${classes.btn} ${classes.clearBtn}`}
                  >
                    {this.state.pageText.clear}
                  </Button>
                  <Button
                    id="submitButton"
                    variant="contained"
                    disabled={isDisabledSubmit(this)}
                    type="submit"
                    className={`${classes.btn} ${classes.saveBtn} ${!isDisabledSubmit(this) ? classes.enabledBgColor : classes.disabledBackColor
                      }`}
                  >
                    {this.state.pageText.save}
                  </Button>
                </div>)
}
            </form>, 
            <div className={classes.buyerProfileForm}>
      
              <div className={classes.avatarUpload}>
                <div className={classes.avatarPreview}>
                  <img
                    id="avatarPreviewURL"
                    src={conditionSort(this.computedAvatarURL(), this.computedAvatarURL(), "https://placehold.co/120x120")}
                    alt=""
                    className={classes.avatarPreviewImg}
                  />
                </div>
                <div className={classes.avatarUploadRight}>
                  <div className={classes.avatarTitle}>{this.state.pageText.fullProfileInfo}</div>
                  
                </div>
                {conditionSort(this.state.buyerId, null, <div style={styles.contentTwo}
                  data-test-id="profileBtnTestId"
                  onClick={() => this.handleEditBtn()}
                >{this.state.pageText.editProfile}</div>)}
                
              </div>

              <div className={classes.inputWrapperGroup}>
                {this.GenInfo(this.state.pageText.firstName, 'firstName', firstName)}
                {this.GenInfo(this.state.pageText.lastName, 'lastName', lastName)}
              </div>

              <div className={classes.inputWrapperGroup}>
                
                {this.GenInfo(this.state.pageText.dob, 'dateOfBirth', dateOfBirth)}

                {this.GenInfo(this.state.pageText.gender, 'gender', mainGList.find((obje: {title: string, value: string}) => obje.value == this.state.gender )?.title)}

              </div>
              {this.GenInfo(this.state.pageText.selectRole, 'role', this.state.role)}

             

              {this.GenInfo(this.state.pageText.linkedIn, 'linkedinLink', conditionSort(!!linkedinLink, linkedinLink, '-') )}
             
              {conditionSort(!sellerType, <>
                {this.GenInfo(this.state.pageText.selectCountry, 'selectedCountry', selectedCountryT?.title)}

                {this.GenInfo(this.state.pageText.selectCity, 'citySelect', selectedCountryT?.cities.find((element) => element.value == selectedCity)?.title)}
                  
               
                </>, null) 
                
              }

              {conditionSort(sellerType, <>{this.GenInfo(this.state.pageText.preferredLang, 'preferred_lang', this.state.preferred_lang)}</>, null)}

              {this.GenInfo(this.state.pageText.aboutYourself, 'aboutYourself', this.state.aboutYourself)}
           
              {!sellerType &&
                <>
                  {this.GenInfo(this.state.pageText.noOf, 'numberAcquisitionClosed', numberAcquisitionClosed)}

                  {this.GenInfo(this.state.pageText.pro, 'numberProjectedAnnualAquisitions', numberProjectedAnnualAquisitions)}
                  
                  {this.GenInfo(this.state.pageText.acc, 'accreditedBuyer', accreditedBuyerOptions.find((obje: {title: string, value: string}) => obje.value == this.state.accreditedBuyer )?.title)}
                  
                </>
              }</div>)}
           
            
           {conditionSort(!!this.state.buyerId, <div style={styles.buylerId}>
              <Button id="seller-btn"
              onClick={() => this.navigateToRoute(conditionSort(this.state.userType == 'seller', 'MyAcquisition', 'AcquisitionProcess'))}
                variant="contained"
                className={`${classes.btn} ${classes.saveBtn} ${classes.enabledBgColor}`}
              >
                {conditionSort(this.state.userType == 'seller', this.state.pageText.backBtn, this.state.pageText.backBtnB)}
              </Button>
            </div>, null)}
          </main>
        </div>
        <FooterBlock />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  infoStyle: { 
    fontWeight: 600
   },
  contentOne: {
    margin: 0, 
    textTransform: "capitalize" as "capitalize"
  },
  contentTwo: {
    marginLeft: 'auto',
    color: '#5412FC',
    border: 'none',
    cursor: 'pointer',
    fontSize: '18px',
    background: 'transparent',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: "20px",
    whiteSpace: 'nowrap' as "nowrap",
    textDecoration: 'underline'
  },
  buylerId: {
    textAlign: 'center' as "center",
    marginTop: "20px"
  }
}
const customStyles = (theme: Theme) => {
  return createStyles({
    container: {
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Montserrat, sans-serif",
      "& *": {
        boxSizing: "border-box"
      }
    },
    content: {
      width: "100%",
      maxWidth: "1440px",
      padding: "2rem 3rem",
      fontFamily: "Montserrat, sans-serif",
      lineHeight: "24px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 25,
        paddingLeft: 44,
        paddingRight: 44,
        paddingBottom: 67,
        lineHeight: "20px"
      },
      '@media (min-width: 960px) and (max-width: 1200px)': {

      }
    },
    title: {
      color: "#5412fc",
      fontSize: 30,
      fontWeight: 700,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        lineHeght: "34px",
        marginBottom: 15
      }
    },
    buyerProfileForm: {
      borderRadius: 20,
      border: "2px solid transparent",
      background:
        "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
      padding: "3rem",
      [theme.breakpoints.down("sm")]: {
        borderRadius: 10,
        borderColor: "#00F8A2",
        paddingTop: 45,
        paddingLeft: 37,
        paddingRight: 37,
        paddingBottom: 69
      },
      "& input": {
        width: "100%",
        borderRadius: 12,
        border: "1px solid #8c8c8c",
        color: "#262626",
        fontSize: 20,
        padding: "11px 18px",
        fontFamily: "Montserrat, sans-serif",
        "&::-webkit-scrollbar": {
          width: "8px",
          height: "45px"
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#D9D9D9",
          borderRadius: "4px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "darkgrey"
        },
        [theme.breakpoints.down("sm")]: {
          borderRadius: 8,
          fontSize: 10,
          padding: "9px 12px",
          minHeight: "32px"
        },
        '&[type="date"]': {
          "&::-webkit-calendar-picker-indicator": {
            cursor: "pointer"
          },
          [theme.breakpoints.down("sm")]: {
            padding: "4px 12px"
          }
        }
      },
      "& textarea": {
        outline: "none",
        width: "100%",
        border: "none",
        color: "#262626",
        fontSize: 20,
        fontFamily: "Montserrat, sans-serif",
        minHeight: "160px",
        resize: 'none',
        paddingRight: 8,

        scrollbarWidth: 'thin',
        scrollbarColor: '#D9D9D9 darkgrey',

        '&::-webkit-scrollbar': {
          width: 6, 
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#D9D9D9',
          borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'darkgrey',
        },

        [theme.breakpoints.down("sm")]: {
          minHeight: "68px",
          fontSize: 10,
          paddingRight: 8,
        }
      },
      "& label": {
        display: "block",
        color: "#262626",
        marginBottom: 24,
        [theme.breakpoints.down("sm")]: {
          marginBottom: 4
        }
      }
    },
    textareaWrapper: {
      overflow: "hidden",
      padding: "11px 18px",
      paddingRight: 5,
      borderRadius: 12,
      border: "1px solid #8c8c8c",

      [theme.breakpoints.down("sm")]: {
        borderRadius: 8,
        padding: "9px 12px",
        paddingRight: 3,
      },
    },
    customSelectFormControl: {
      width: "100%",
      "& .arrow-down-icon": {
        position: "absolute",
        width: "24px",
        height: "24px",
        right: "24px",
        pointerEvents: "none",
        [theme.breakpoints.down("sm")]: {
          width: "18px",
          height: "18px",
          right: "12px"
        }
      },
      "& .MuiInputBase-root": {
      },
      "& .MuiFormControl-root": {
        marginLeft: "auto",
        [theme.breakpoints.down("sm")]: {}
      },
      "& .MuiOutlinedInput-root": {
      },
      "& .MuiOutlinedInput-notchedOutline": {
        "&.PrivateNotchedOutline-root-4": {
        }
      },
      "& .MuiOutlinedInput-input": {
        
      },
      "& .MuiMenuItem-root": {
       
      }
    },
    avatarUpload: {
      display: "flex",
      gap: 30,
      alignItems: "center",
      marginBottom: "3.5rem",
      [theme.breakpoints.down("sm")]: {
        gap: 16,
        marginBottom: 29
      }
    },
    avatarPreviewImg: {
      width: 120,
      height: 120,
      objectFit: "cover",
      borderRadius: "50%",
      [theme.breakpoints.down("sm")]: {
        width: 63,
        height: 63
      }
    },
    avatarTitle: {
      color: "#000",
      fontSize: 24,
      fontWeight: 700,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        lineHeight: 1,
        marginBottom: 11
      }
    },
    selectedDocument: {
      paddingTop: "5px",
      fontSize: "14px",
      color: "#8c8c8c"
    },
    getDocumentURL: {
      display: "inline-block",
      paddingTop: "5px",
      fontSize: "14px",
      color: "#8c8c8c",
      border: "none",
      background: "none",
      textDecoration: "underline"

    },
    verifyDropper: {
      cursor: "pointer",
      paddingTop: "37px",
      paddingBottom: "34px",
      
      borderRadius: "20px",
      border: "2px dashed #8c8c8c",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "11px",
        paddingBottom: "11px",
        paddingLeft: "54px",
        paddingRight: "54px",
        borderWidth: "1px",
        borderRadius: "8px"
      }
    },
    dropperContent: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      alignItems: "center",
      pointerEvents: "none",
      [theme.breakpoints.down("sm")]: {
        gap: "4px",
        textAlign: "center"
      }
    },
    uploadIcon: {
      width: "36px",
      height: "36px",
      [theme.breakpoints.down("sm")]: {
        width: "18px",
        height: "18px"
      }
    },
    dropperText: {
      color: "#8C8C8C",
      fontSize: "20px",
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        fontSize: "8px",
        lineHeight: 1
      }
    },
    updateAvatarLabel: {
      cursor: "pointer",
      marginBottom: "0 !important"
    },
    updateAvatarLink: {
      pointerEvents: "none",
      color: "#000",
      fontSize: "24px",
      fontWeight: 400,
      textDecorationLine: "underline",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        lineHeight: 1
      }
    },
    inputWrapper: {
      fontSize: 24,
      fontWeight: 500,
      marginBottom: "2rem",
      flex: 1,
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        marginBottom: 15
      },
      "& input[type='date']::-webkit-calendar-picker-indicator": {
        opacity: 1,
        display: "block",
        backgroundImage: `url(${calendarIcon})`,
        backgroundSize: "cover",
        width: "24px",
        height: "24px",
        [theme.breakpoints.down("sm")]: {
          width: "18px",
          height: "18px"
        }
      },
      "& input[type='date']:focus::-webkit-datetime-edit": {
        color: "black !important"
      }
    },
    inputWrapperGroup: {
      display: "flex",
      gap: 100,
      fontSize: 24,
      fontWeight: 500,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: 0,
        fontSize: 10
      }
    },
    requiredAsterisk: {
      display: "inline-block",
      color: "#ff7575"
    },
    formButtons: {
      marginTop:"5.5rem",
      display: "flex",
      justifyContent: "center",
      gap: 100,
      [theme.breakpoints.down("sm")]: {
        marginTop: 41,
        gap: 0,
        justifyContent: "space-between"
      }
    },
    btn: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: 20,
      fontWeight: 700,
      borderRadius: 12,
      padding: "10px 75px",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10,
        padding: "4px 28px",
        borderRadius: 5,
        minHeight: "25px"
      }
    },
    clearBtn: {
      border: "2px solid #8c8c8c",
      background: "#fff",
      color: "#8c8c8c"
    },
    saveBtn: {
      border: "none",
      background: "#8c8c8c",
      color: "#fff"
    },
    setCapitalize: {
      textTransform: "capitalize"
    },
    enabledBgColor: {
      backgroundColor: "rgb(16, 191, 184) !important"
    }
    , AgeErrorMess: {
      fontSize: "16px",
      padding: "5px 8px 0px 8px",
      color: "#df3535"
    },
    disabledBackColor: {
      backgroundColor: "#8c8c8c !important",
      color: "#fff !important"
    },
    radioFieldContainer: {
      display: "flex",
      gap: "20px"
    },
    radioGrpWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "20px"
    },
    radioStyle: {
      width: "20px !important",
      [theme.breakpoints.down("sm")]: {
        width: "13px !important",
        minHeight: "auto !important"
      },
    },
  });
};

export const EditBuyerProfile = withStyles(customStyles)(BuyerProfile);
// Customizable Area End
