//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
  // Customizable Area Start
import {
  Box, Typography,
  styled,
  Accordion,
  AccordionSummary,AccordionDetails
} from "@material-ui/core";
import { dropIcon,searchIcon } from "./assets";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
  // Customizable Area Start
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
    secondary: {
      main: "#376fd0",
      contrastText: "#fff",
    },
    error: {
      main: "#f44336",
      contrastText: "#fff",
    }
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
      <HeaderBlock/>
      <Main_Box>
        <HeadingBox>
          <TypographyHeading data-test-id="heading-main">
          {
              this.state.lang==="ar"?"الأسئلة الشائعة":"Frequently Asked Questions"
            }
          </TypographyHeading>
        </HeadingBox>
        <SearchBoxContainer>
          <SearchBox
            type='text'
            data-test-id="search-main"
            placeholder= {this.state.lang==="ar"?"بحث":"Search"}
            value={this.state.text}
            onChange={this.handleChange}
          />
          <SearchImage src={searchIcon}/>
        </SearchBoxContainer>
        <ScrollBarBox>
        {(!this.state.showNoResult?
          this.state.result.length >0 && this.state.result.map((item:any,index:any)=>{
           return (<CustomAccordion
           key={index}
           className={this.state.filterResult.length === 0 || (this.state.filterResult.length > 0 && this.state.filterResult.includes(item)) ? '' : 'hideData'}
          >
            <AccordionSummary
                expandIcon={<DropImage src={dropIcon}/>}
                aria-controls="panel1a-content"
            >
                <TypographyBoxContent>
                {item.question}
                </TypographyBoxContent>
            </AccordionSummary>
            <AccordionDetails>
                <TypographyBoxContent>{item.answer}</TypographyBoxContent>
            </AccordionDetails>
        </CustomAccordion>)
          })    
        :<Box style={{display:'flex',justifyContent:'center'}}>
         {
              this.state.lang==="ar"?"لم يتم العثور على نتيجة":"No Result Found"
            }  
        </Box>)}

        </ScrollBarBox>
      </Main_Box>
      <FooterBlock/>
      </>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const Main_Box=styled(Box)({
  border: '2px transparent solid',
  borderRadius:'20px',
  margin: "2rem auto",
  minWidth:'1000px',
  padding: "24px",
  gap: "18px",
  display: "flex",
  flexDirection: "column",
  background: "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
})
const TypographyHeading=styled(Typography)({
color: '#5412FC',
textAlign: 'center',
fontFamily: 'Montserrat',
fontSize: '30px',
fontStyle: 'normal',
fontWeight: 700
});
const SearchBox=styled('input')({
  width:'48%',
  height:'100%',
  fontSize:'16px',
  fontStyle:'italic',
  borderRadius:'32px',
  border: '1px solid #8C8C8C',
  background:'#fff',
  padding:'0px 24px',
  fontWeight: "300",
  fontFamily:'Montserrat',
  "@media(max-width: 600px)": {
    height:'27px !important',
    fontSize:'10px !important',
    padding:'0px 10px !important'
  },
  "@media(min-width: 600px)and (max-width:960px)": {
    fontSize:'15px !important',
    padding:'0px 20px'
  }
});
const ScrollBarBox = styled(Box)({
  maxHeight: "500px",
  minHeight: "200px",
  height: '100%',
  overflow:'auto',
  overscrollBehavior: 'contain',
  boxSizing: "border-box",
  overflowX:'hidden',
  width: 'calc(100% - 7px)',
  padding: '0px 20px',
  '&::-webkit-scrollbar': {
    width: '6px',
    height:'45px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#D9D9D9', // Color of the scrollbar thumb
    borderRadius: '4px',
     // Adjust this value to change the radius of the thumb
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: 'darkgrey', // Color of the scrollbar thumb on hover
  },
  "@media(max-width: 600px)": {
    padding: '0px 26px',
  }
});
const CustomAccordion=styled(Accordion)({
  transition: 'min-height 1s ease-out,opacity 1s,max-height 1s ease-in',
  minHeight:'65px',
  boxShadow:'none',
  margin:'2% auto 0 auto',
  boxShadown:'none',
  border:'1px solid #8C8C8C',
  display:'flex',
  flexDirection:'column',
  borderRadius:'12px',
  justifyContent:'center',
  width:'90%',
  "&.hideData":{
    minHeight:'0px',
    marginBottom:"0px",
    maxHeight:"0px",
    opacity:0
  },
  "& .MuiAccordionSummary-root":{
    transition: 'min-height 650ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    , 
    "& *": {
      cursor: "pointer",
    },
    "@media(max-width: 600px)": {
      minHeight: "44px"
    }
  },
  "& .MuiAccordionDetails-root":{
    paddingBottom: "34px",
    "& p":{
      fontSize: "16px",
      fontWeight: 300
    },
  },
  "& .MuiAccordionSummary-content" :{
    transition: 'margin 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  "& .MuiAccordionSummary-expandIcon" :{
    transition: 'transform 650ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  "&.MuiAccordion-root.Mui-expanded" :{
    marginLeft:'auto !important',
    marginRight:'auto !important',
    maxHeight:'1000px',
    "&.hideData":{
      minHeight:'0px',
      marginBottom:"0px",
      maxHeight:"0px",
      opacity:0
    },
  },
  "&.MuiAccordion-root:before" :{
    backgroundColor:'unset !important'
  },
  "&.MuiAccordion-rounded:first-child":{
    borderRadius:'16px'
  },
  "&.MuiAccordion-rounded:last-child":{
    borderRadius:'16px'
  },
  "@media(max-width: 600px)": {
    minHeight:'32px',
    borderRadius:'8px !important',
    width: "100%",
    marginBottom:'12px',
    "&.hideData":{
      minHeight:'0px !important',
      marginBottom:'0px !important'
    }
  },
});
const TypographyBoxContent=styled(Typography)({
  color: '#000',
  fontFamily: 'Montserrat',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: "32px",
  "@media(min-width: 600px) and (max-width:960px)": {
    fontSize:'15px !important'
  },
  "@media(max-width: 600px)": {
    fontSize:'8px !important',
    lineHeight: "10px",
  }
});
const HeadingBox=styled(Box)({
  "& p": {
    fontSize: "26px"
  }
});
const SearchBoxContainer=styled(Box)({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  height: "48px",
  gap:'18px',
  "@media(max-width: 600px)": {
    gap:'8px !important'
  }
});
const DropImage=styled('img')({
  width:'28px',
  height:'28px',
  "@media(max-width: 600px)": {
    width:'15px !important',
    height:'15px !important'
  },
  "@media(min-width: 600px) and (max-width:960px)": {
    width:'24px !important',
    height:'24px !important'
  },
})
const SearchImage=styled('img')({
  width:'64px',
  height:'100%',
  "@media(max-width: 600px)": {
    width:'27px !important',
    height:'27px !important'
  },
})

// Customizable Area End
