import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
import { businessLogo, rocketIcon } from "./assets";
import { styles } from "./CatalogueList.web";
import { toast } from "react-toastify";
import { getStorageData } from "framework/src/Utilities";

export enum CatalogueType {
  BUSINESS = "sme",
  STARTUP = "start up",
  BUSINESSAr = "س  مه",
  STARTUPAr = ".بدء",
}
export interface BookmarkTypes {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
  };
}

export interface FlagReportTypes {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
  };
}
export interface InitialValuesTypes {
  listTitle: string;
}
export type Props = WithStyles<typeof styles> & {
  id: string;
  title: string;
  titleImgSrc: any;
  type: CatalogueType;
  listyingType: string;
  description: string;
  annualRevenue: string;
  employeesCount: number;
  annualProfit: string;
  foundedIn: string;
  basedIn: string;
  proposedValuation: string;
  originListing: string;
  token: string;
  refreshList: () => void;
  isBookmark: boolean | null;
  // Customizable Area Start
  pageText : {
    dealClosedText: string;
    businessText: string;
    startUpText: string;
    numberOfEmployeesText: string;
    foundedInText: string;
    proposedEvalutionText: string;
    AnnualRevenueText: string;
    LinkedInText:string;
    annualProfitText: string;
    basedInText: string;
    bookmarkModalTitle: string;
    addListTxt: string;
    originalListingText: string;
    bookmarkText: string;
    archiveReportText: string;
   
    archiveListingText: string;
    yesText: string;
    noText: string;
    ArchiveText: string;
    chooseThisWillText: string;
    hideTheListingText: string;
    copiedTxtMsg:string;
    listTitleTextField:string;
    reportText: string;
    flagTheListing: string;
    viaLinkText: string;
    
    shareListText: string;
    unBookMarkText: string;
    TwitterText: string;
    faceBookText: string;
    instragramText: string;
    whatsAppText: string;
    flagBoxTitle: string;
    flagBoxDetails: string;
    shareBoxTitle: string;
    
    reportBlogTitle: string;
    
  }
  language:string
  isDealClosed: boolean;
  requestInitiated: boolean;
  is_flag:boolean;
  // Customizable Area End
};

interface S {
  bookmarkModal: boolean;
  bookmarkCreate: boolean;
  flagHandle: boolean;
  flagModalStatus: boolean;
  shareHandler: boolean;
  flagArchiveModalState: boolean;
  bookmarkList: Array<BookmarkTypes>;
  flagReportList: Array<FlagReportTypes>;
  isSeller: boolean;
}
interface SS {
  id: any;
}

export default class CatalogueListController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookmarkListCallId: string = "";
  reportCatalogCallID: string = "";
  archiveCatalogCallID: string = "";
  createListCallID: string = "";
  bookmarkCallID: string = "";
  unBookmarkCallID: string = "";
  getFlagReportListCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      bookmarkModal: false,
      bookmarkCreate: false,
      flagHandle: false,
      flagModalStatus: false,
      shareHandler: false,
      flagArchiveModalState: false,
      bookmarkList: [],
      flagReportList: [
        {
          id: "1",
          type: "catalogue_list",
          attributes: {
            id: 1,
            name: "Inaccurate Listing Details",
          },
        },
        {
          id: "2",
          type: "catalogue_list",
          attributes: {
            id: 2,
            name: "Suspicious or Fraudulent",
          },
        },
        {
          id: "3",
          type: "catalogue_list",
          attributes: {
            id: 3,
            name: "Irrelevent or spam",
          },
        },
        {
          id: "36",
          type: "catalogue_list",
          attributes: {
            id: 36,
            name: "Inappropriate Content",
          },
        },
        {
          id: "36",
          type: "catalogue_list",
          attributes: {
            id: 36,
            name: "Duplicate Listing",
          },
        },
        {
          id: "4",
          type: "catalogue_list",
          attributes: {
            id: 4,
            name: "Breach of Terms of Service",
          },
        },
      ],
      isSeller:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.recieveBookmarkList(message);
    this.recieveReport(message);
    this.recieveArchive(message);
    this.recieveCreateTitle(message);
    this.recieveBookmark(message);
    this.receiveFlagReportList(message);
    this.recieveUnBookmark(message);

    // Customizable Area End
  }

  handleShareLink = (text: string) => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(String(text));
    this.props.refreshList();
    toast.success(this.getContent(this.props.pageText.copiedTxtMsg,configJSON.copiedTxtMsgArabic));
  };

  recieveCreateTitle = (message: Message) => {
    const [responseJson, apiRequestCallId] = this.getResponseVariables(message);
    if (this.createListCallID === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.bookmarkCatalogue(responseJson.data.attributes.id);
        this.setState(
          {
            bookmarkCreate: false,
          }
        );
      } else if (responseJson && responseJson.errors) {
        toast.error(responseJson.errors[0]);
      }
    }
  };

  recieveBookmarkList = (message: Message) => {
    const [responseJson, apiRequestCallId] = this.getResponseVariables(message);
    if (this.getBookmarkListCallId === apiRequestCallId) {

      if (responseJson && responseJson.data) {
        
        this.setState({
          bookmarkList: responseJson.data,
          bookmarkModal: true,
        });
      }
    }
  };

  receiveFlagReportList = (message: Message) => {
    const [responseJson, apiRequestCallId] = this.getResponseVariables(message);
    if (this.getFlagReportListCallID === apiRequestCallId) {
      if (responseJson && responseJson.data) {
        this.setState({
          flagReportList: responseJson.data,
          flagModalStatus: true,
        });
      } else {
        this.setState({
          flagModalStatus: !this.state.flagModalStatus,
        });
      }
    }
  };

  recieveBookmark = (message: Message) => {
    const [responseJson, apiRequestCallId] = this.getResponseVariables(message);
    if (this.bookmarkCallID === apiRequestCallId) {
      if (responseJson && responseJson.message) {
        this.setState({
          bookmarkModal: false,
        });
        toast.success(this.props.language === "en" ? responseJson.message[0] : responseJson.arabic_message[0] )
      } else if (responseJson && responseJson.errors) {
        this.setState(
          {
            bookmarkModal: false,
          },
          () => toast.error(responseJson.errors.token)
        );
      }
      this.props.refreshList();
    }
  }; 

  recieveUnBookmark = (message: Message) => {
    const [responseJson, apiRequestCallId] = this.getResponseVariables(message);
    if (this.unBookmarkCallID === apiRequestCallId) {
      if (responseJson && responseJson.message) {
        toast.success(this.props.language === "en" ? responseJson.message : responseJson.arabic_message);
        this.props.refreshList();
      } else if (responseJson && responseJson.errors) {
        toast.error(responseJson.errors.token);
      }
    }
  };

  recieveReport = (message: Message) => {
    const [responseJson, apiRequestCallId] = this.getResponseVariables(message);
    if (this.reportCatalogCallID === apiRequestCallId) {
      if (responseJson.message) {
        toast.success(this.props.language === "en" ? responseJson.message : responseJson.arabic_message);
        this.handleFlagCloseModal();
        this.props.refreshList();
      }
    }
  };

  recieveArchive = (message: Message) => {
    const [responseJson, apiRequestCallId] = this.getResponseVariables(message);
    if (this.archiveCatalogCallID === apiRequestCallId) {
      if (responseJson.message) {
        this.flagArchiveModal();
        this.props.refreshList();
      }
    }
  };

  catalogueReport = async (id: string) => {
    const requestBody = {
      user_archieved_catalogues: {
        report_id: id,
        is_reported: "true",
        listing_id: this.props.id,
        is_archieved:"false",
      },
    };
    const requestMessage = await this.generateRequestMessage(
      `${configJSON.reportApiEndPoint}?locale=${this.props.language}`,
      configJSON.apiMethodTypePost,
      this.props.token,
      requestBody
    );
    this.reportCatalogCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContent = (englishContent:string | {
    shareBoxTitle:string;
    flagBoxDetails:string;
    faceBookText:string;
    whatsAppText:string;
    viaLinkText:string;
    instragramText:string;
    TwitterText:string;
    LinkedInText:string;
    addListTxt:string;
    listTitleTextField:string;
    bookmarkModalTitle:string;
    chooseThisWillText:string;
    flagTheListing:string;
    reportBlogTitle:string;
    yesText:string;
    flagBoxTitle:string;
    archiveListingText:string;
    reportText:string;
    noText:string;
    ArchiveText:string;
    hideTheListingText:string;
  }, arabicContent:string | {
    shareBoxTitle:string;
    flagBoxDetails:string;
    faceBookText:string;
    whatsAppText:string;
    viaLinkText:string;
    instragramText:string;
    TwitterText:string;
    LinkedInText:string;
    addListTxt:string;
    listTitleTextField:string;
    bookmarkModalTitle:string;
    chooseThisWillText:string;
    flagTheListing:string;
    reportBlogTitle:string;
    yesText:string;
    flagBoxTitle:string;
    archiveListingText:string;
    reportText:string;
    noText:string;
    ArchiveText:string;
    hideTheListingText:string;
  }) => {
    return this.props.language === "en" ? englishContent : arabicContent;
  }

  archiveHandle = async () => {
    const requestBody = {
      user_archieved_catalogues: {
        is_archieved: true,
        listing_id: this.props.id,
      },
    };
    const requestMessage = await this.generateRequestMessage(
      configJSON.reportApiEndPoint,
      configJSON.apiMethodTypePost,
      this.props.token,
      requestBody
    );
    this.archiveCatalogCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTypeInfo = (type: CatalogueType) => {
    const typeImg =
    ((type?.toLowerCase() === CatalogueType?.BUSINESS?.toLowerCase()) || (type === CatalogueType?.BUSINESSAr) ) ? businessLogo : rocketIcon;
    const typeColor =
    ((type?.toLowerCase() === CatalogueType?.BUSINESS?.toLowerCase()) || (type === CatalogueType?.BUSINESSAr) ) ? "#5412FC" : "#10BFB8";

    const mainBoxShadow =
      ((type?.toLowerCase() === CatalogueType?.BUSINESS?.toLowerCase()) || (type === CatalogueType?.BUSINESSAr) )
        ? "rgba(84, 18, 252, 0.6)"
        : "rgba(16, 191, 184, 0.6)";
    return { typeImg, typeColor, mainBoxShadow };
  };

  handleBookmarkModal = () => {
    this.getBookmarkList();
  };
  closeBookmark = () => {
    this.setState({
      bookmarkModal: false,
    });
  };

  handleBookmarkCreate = () => {
    this.setState({
      bookmarkCreate: !this.state.bookmarkCreate,
    });
  };

  handleFlage = () => {
    this.setState({
      flagHandle: !this.state.flagHandle,
    });
  };

  handleFlagModal = () => {
    this.getFlagReportList();
  };

  handleFlagCloseModal = () => {
    this.setState({
      flagModalStatus: false,
    });
  };

  handleShare = () => {
    this.setState({
      shareHandler: !this.state.shareHandler,
    });
  };

  flagArchiveModal = () => {
    this.setState({
      flagArchiveModalState: !this.state.flagArchiveModalState,
    });
  };

  bookmarkCatalogue = async (listID: number) => {
    const requestBody = {
      catalogue_list: {
        listing_id : this.props.id,
        catalogue_list_id: listID,
      },
    };
    const requestMessage = await this.generateRequestMessage(
      configJSON.bookmarkEndPoint,
      configJSON.apiMethodTypePut,
      this.props.token,
      requestBody
    );
    this.bookmarkCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  UnBookmarkCatalogue = async () => {
    const requestBody = {
      id: this.props.id,
    };
    const requestMessage = await this.generateRequestMessage(
      configJSON.unBookmarkEndPoint,
      configJSON.apiMethodTypePut,
      this.props.token,
      requestBody
    );
    this.unBookmarkCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleListSubmit = async (values: InitialValuesTypes) => {
    const requestBody = {
      catalogue_list: {
        title: values.listTitle,
      },
    };
    const requestMessage = await this.generateRequestMessage(
      configJSON.bookmarkListEndPoint,
      configJSON.apiMethodTypePost,
      this.props.token,
      requestBody
    );
    this.createListCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBookmarkList = async () => {
    const requestMessage = await this.generateRequestMessage(
      `${configJSON.bookmarkListEndPoint}?locale=${this.props.language}`,
      configJSON.apiMethodTypeGet,
      this.props.token
    );

    this.getBookmarkListCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  langConditionRendering = (valueOne:string,valueTwo:string) =>{
    if(this.props.language ==="en"){
      return valueOne
    }else{
      return valueTwo
    }
  }

  getFlagReportList = async () => {
    const requestMessage = await this.generateRequestMessage(
      `${configJSON.flagReportEndPoint}`,
      configJSON.apiMethodTypeGet,
      this.props.token
    );
    this.getFlagReportListCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //Common Functions
  generateRequestMessage = async (
    endpoint: string,
    type: string,
    token: string,
    body: any = null
  ) => {
    const headers = {
      "content-type": "application/json",
      token: this.props.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      type
    );
    body != null &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    return requestMessage;
  };

  trimString = (data: string | null, limit: number) => {
    if (data === null || data === undefined) {
      return "";
    }
    if (data.length < limit) {
      return data;
    }
    return (
      data
        .substring(0, limit)
        .substring(0, data.substring(0, limit).lastIndexOf(" ")) + "..."
    );
  };

  getResponseVariables = (message: Message) => {
    const apiRequestCallID = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJSON = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    return [responseJSON, apiRequestCallID];
  };

  async componentDidMount() {
    const userType = await getStorageData("typeOfUser");
    this.handleSeller(userType);
  };
  
  handleSeller = (userType: string) => {
    if (userType === "seller") {
      this.setState({ isSeller: true });
    }
  };

  handleDisable = () => {
    if (this.state.isSeller || this.props.isDealClosed) {
      return true;
    }
    return false;
  };

}
