export const backArrow = require("../assets/back-arrow.png");
export const image = require("../assets/image.png");
export const image1 = require("../assets/image1.svg");
export const AssessBuyers = require("../assets/Assess-Buyers.svg");
export const AssessBuyersSelected = require("../assets/Assess-Buyers-Selected.svg");
export const FacilitatePaymentAssetHandover = require("../assets/Facilitate-Payment-Asset-Handover.svg");
export const FacilitatePaymentAssetHandoverSelected = require("../assets/Facilitate-Payment-Asset-Handover-Selected.svg");
export const FinalizeAcquisition = require("../assets/Finalize-Acquisition.png");
export const HandlingProposals = require("../assets/Handling-Proposals.svg");
export const HandlingProposalsSelected = require("../assets/Handling-Proposals-Selected.svg");
export const UndergoingDueDiligence = require("../assets/Undergoing-Due-Diligence.svg");
export const UndergoingDueDiligenceSelected = require("../assets/Undergoing-Due-Diligence-Selected.svg");
export const infoIcon = require("../assets/info-icon.png");
export const infoIconSelected = require("../assets/info-icon-selected.png");
export const MeasureInterest = require("../assets/Measure-Interest.png");
export const ProvideInformation = require("../assets/Provide-Information.png");
export const SetTimelines = require("../assets/Set-Timelines.png");
export const Check = require("../assets/Check.png");
export const DiscussTerms = require("../assets/Discuss-Terms.png");
export const Respond = require("../assets/Respond.png");
export const GatherProof = require("../assets/Gather-Proof.png");
export const InteractWithBuyers = require("../assets/Interact-with-Buyers.png");
export const SealTheDealWithAPA = require("../assets/Seal-the-Deal-with-APA.png");
export const InitiateEscrow = require("../assets/Initiate-Escrow.png");
export const ShiftAssets = require("../assets/Shift-Assets.png");
export const ConfirmPayment = require("../assets/Confirm-Payment.png");
export const Congratulations = require("../assets/Congratulations.png");
export const closeIcon = require("../assets/close-icon.svg");
export const chatIcon = require("../assets/chat-icon.svg");
export const arrowDown = require("../assets/arrow-down.svg");
export const arrowDownBlack = require("../assets/arrow-down-black.svg");
export const documentIcon = require("../assets/document-icon.svg");
export const documentIconColor = require("../assets/document-icon-color.svg");
export const uploadDocumentIcon = require("../assets/upload-document-icon.svg");
export const clearIcon = require("../assets/clear-icon.png");
export const rightIcon = require("../assets/right-icon.svg");
export const arrowRight = require("../assets/arrow-right.svg");
export const addIcon = require("../assets/add-icon.svg");
export const foldedArrow = require("../assets/FoldedArrow.svg");

export const evaluate_company = require("../assets/stepperImg/evaluate_company.png");
export const facilitate_payment_asset_handover = require("../assets/stepperImg/facilitate_payment_asset_handover.png");
export const finalize_acquisition = require("../assets/stepperImg/finalize_acquisition.png");
export const handling_proposals = require("../assets/stepperImg/handling_proposals.png");
export const undergoing_diligence = require("../assets/stepperImg/undergoing_diligence.png");
export const backgroundArrow1 = require("../assets/arrow2.svg");
export const FullStarIcon = require("../assets/fullStar.svg");
export const EmptyStarIcon = require("../assets/emptyStar.svg");
export const Step1Disable = require("../assets/stepperImg/step-1.svg");
export const Step2Disable = require("../assets/stepperImg/step-2.svg");
export const Step3Disable = require("../assets/stepperImg/step-3.svg");
export const Step4Enable = require("../assets/stepperImg/step-4.svg");
export const Step5Enable = require("../assets/stepperImg/step-5.svg");