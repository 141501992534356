import React from "react"
import {Box,Button,Typography, withStyles } from "@material-ui/core";
import NewsGridController, { configJSON, AttributesType } from "./NewsGridController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
const images = require("./assets");
//Customizable Area Start
import {Styles} from "./Styles.web";
//Customizable Area End

export class NewsGrid extends NewsGridController {
  
  //Customizable Area Start
  newsBlock = (item:AttributesType) => {
    const { classes } = this.props;
    return (<Box className={classes.NewsBlock} data-test-id={`news-data-${item.id}`}key={item.id}>
      <Box className={classes.NewsHeading}>
          <span className={classes.imgBlock}>
          <img src={item.image_url != null ? item.image_url : images.newsThumb} alt={item.title} className={classes.newsThumb} />
          </span>
          {item?.title && <Typography component="span" className={classes.newsTitle}>{this.trimString(item.title, 60)}</Typography>}
      </Box>
      {item?.description && <p className={classes.newsData}>{this.trimString(item.short_description, 120)}</p>}
      <Box className={classes.NewsBlockFooter}>
        <Button onClick={()=>this.moreNews(item.id)} data-test-id={`read-more-btn-${item.id}`} className={classes.readMoreBtn}>  { this.state.lang==="ar"?"اقرأ أكثر": "Read More"}</Button> 
      </Box>
    </Box>)
  }
  //Customizable Area End


  render() {
    //Customizable Area Start
    const { classes } = this.props;
    const {newsData} = this.state;
    //Customizable Area End

    return (
    <>
    <HeaderBlock/>

    
    {/* Customizable Area Start */}
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Typography component="h1" className={classes.pageTitle}> 
        { this.state.lang==="ar"?configJSON.newsTitleArabic: configJSON.newsTitle}
        </Typography>
      </Box>
      <Box className={classes.inner_wrapper}>
        <Box className={classes.NewsWrapper} data-test-id="news-content">
          {newsData.length > 0 && newsData.map((item:AttributesType) => {
            return this.newsBlock(item);
          })}
        </Box>
      </Box>
    </Box>
    {/* Customizable Area End */}


    <FooterBlock/></>
    );
    
  }
}



// Customizable Area Start
// Customizable Area End
export default withStyles(Styles)(NewsGrid);