import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { getStorageData } from "framework/src/Utilities";
import { conditionSort } from "./Helper";
import { Box, Paper, Typography } from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  stepperMenuSection: {
    background: "white",
    position: "relative",
    "&:last-child div::after": {
      display: "none",
    },
  },
  stepperMenu: {
    borderRadius: "20px",
    border: "2px #262626 solid",
    padding: "40px 20px 0",
    width: "120px",
    minHeight: "180px",
    textAlign: "center",
    "&::after": {
      right: "-115px",
      bottom: "90px",
      content: "'---------------------'",
      position: "absolute",
    },
  },
  stepperMenuAr: {
    borderRadius: "20px",
    padding: "40px 20px 0",
    border: "2px #262626 solid",
    width: "120px",
    minHeight: "180px",
    textAlign: "center",
    "&::after": {
      left: "-124px",
      bottom: "90px",
      content: "'---------------------'",
      position: "absolute",
    },
  },
  stepperMenuImg: {
    width: "100px",
    marginBottom: "16px",
    height: "100px"
  },
  stepperMenuText: {
    color: "#000000",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "14px",
    margin: 0,
  },
  stepperInfoIcon: {
    position: "absolute",
    top: "12px",
    right: "12px",
  },
  overlay: {
    position: "fixed",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1,
  },
  popup: {
    maxWidth: "90%",
    width: "300px",
    position: "absolute",
    zIndex: 20,
    top: "34px",
    right: "50%",
    transform: "translateX(50%)",
    border: "1px solid #ddd",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    justifyContent: "center",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    borderRadius: "8px",
  },
  popupTitle: {
    fontSize: "16px",
    marginBottom: "8px",
    fontWeight: "bold",
  },
  popupDescription: {
    color: "#555",
    fontSize: "14px",
  },
  closeButton: {
    alignSelf: "flex-end",
    cursor: "pointer",
  },
}));

interface AcquisitionStepTypeProps {
  stepperList: {
    id: number;
    img: any;
    selectedImg: any;
    label: string;
    learnMoreList: {
      icon: any;
      title: string;
      description: string;
    }[];
  }[];
  lang: string;
  activeStep: number;
  infoIconSelected: any;
  infoIcon: any;
}


export const AcquisitionProcessSteps: React.FC<AcquisitionStepTypeProps> = ({
  stepperList,
  activeStep,
  infoIconSelected,
  infoIcon,
  lang
}) => {
  const classes = useStyles();

  const iList = [
    {
      title: "Measure Interest",
      description: "Engage with potential buyers and understand their objectives before sharing detailed information.",
      arabic_title: "قياس الفائدة",
      arabic_description: "تواصل مع المشترين المحتملين وافهم أهدافهم قبل مشاركة المعلومات التفصيلية.",
    },
    {
      title: "Check",
      description: "Even though LOIs may have non-binding elements, some terms can be legally enforceable. Consult with a legal expert.",
      arabic_title: "تحقق",
arabic_description: "على الرغم من أن خطابات النوايا قد تحتوي على عناصر غير ملزمة، إلا أن بعض الشروط يمكن أن تكون قابلة للتنفيذ قانوناً. استشر خبيراً قانونياً."
    },
    {
      title: "Gather Proof",
      arabic_title: "جمع الأدلة",
      description: "Due diligence involves engaging all functions of the business. Collect and provide evidence supporting your company’s operations and claims",
      arabic_description: "تتضمن العناية الواجبة إشراك جميع وظائف الشركة. جمع وتقديم الأدلة التي تدعم عمليات شركتك ومطالباتها"
    },
    {
      title: "Initiate Escrow",
      description: "Begin the escrow process with the buyer using the dedicated escrow service.",
      arabic_title: "بدء الضمان",
      arabic_description: "ابدأ عملية الضمان مع المشتري باستخدام خدمة الضمان المخصصة."
    },
    {
      title: "Congratulations!",
      arabic_title: "تهانينا!",
      description: "",
      arabic_description: ""
    }
  ]

  const [selectedStep, setSelectedStep] = useState<number | null>(null);

  const iconcard = (stepId: number) => {
    return (
      <>
      {selectedStep !== null && <div className={classes.overlay} />}
      <Box className={classes.popup}>
      <Box className={classes.closeButton}>
        <CancelIcon onClick={() => setSelectedStep(null)} />
      </Box>
      <Box>
        <Typography className={classes.popupTitle}>
          {conditionSort(lang == 'ar', iList[stepId - 1].arabic_title, iList[stepId - 1].title)}
        </Typography>
        <Typography className={classes.popupDescription}>
          {conditionSort(lang == 'ar', iList[stepId - 1].arabic_description, iList[stepId - 1].description)}
        </Typography>
      </Box>
    </Box>
      </>
    );
  };

  return (
    <>
      {stepperList.map((step) => (
        <div key={step.id} className={classes.stepperMenuSection}>
          <div
            className={conditionSort(lang === 'en', classes.stepperMenu, classes.stepperMenuAr)}
            style={{
              opacity: activeStep < step.id ? 0.6 : 1,
              border: activeStep > step.id ? "2px #5412FC solid" : "2px #262626 solid",
              color: activeStep > step.id ? "#5412FC" : "#404040",
            }}
          >
            <img
              src={activeStep > step.id ? step.selectedImg : step.img}
              className={classes.stepperMenuImg}
            />
            <p
              className={classes.stepperMenuText}
              style={{
                fontWeight: activeStep === step.id ? 700 : 500,
                color: activeStep > step.id ? "#5412FC" : "#000000",
              }}
            >
              {step.label}
            </p>
            <div onClick={() => setSelectedStep(step.id)}>
              <img
                src={activeStep > step.id ? infoIconSelected : infoIcon}
                className={classes.stepperInfoIcon}
              />
            </div>
          </div>
            {((selectedStep === step.id) && (activeStep >= step.id)) && iconcard(step.id)}
        </div>
      ))}
    </>
  );
};
