// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";
import { Link } from "@material-ui/core";

import NavigationMenuController, {
  Props,
  configJSON,
  images,
} from "./NavigationMenuController";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
// Customizable Area End

export class ImportantLinks extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const list = this.state.importantLinkList.find((link) => link.id === this.state.selectedLinkType)
    const getLinks = list
      ? list.links.map((link) => (
          <Link href={link.link} key={link.id} className={classes.linkText}>
            {link.description}
          </Link>
        ))
      : null;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <div
          className={classes.mainBox}
          data-test-id="main-important-links"
        >
          <div className={classes.importantLinksHeading}>
          {this.state.lang==="ar"?"روابط مهمة":"Important Links"} 
          </div>
          <div className={classes.importantLinksText}>
            {this.state.lang === "ar" ? configJSON.headTxtAr
              : configJSON.headTxt} 
            
          </div>
          <div className={classes.hr} />
          <div className={classes.linksContent}>
            <div>
              {this.state.importantLinkList.map((link) => (
                <div
                  data-test-id="link-title"
                  key={link.id}
                  className={classes.linkRow}
                  onClick={() => this.handleLinkTitleOnClick(link.id)}
                >
                  <div
                    className={
                      link.id === this.state.selectedLinkType
                        ? `${classes.linkTitle} ${classes.selectedType}`
                        : classes.linkTitle
                    }
                  >
                    {link.name}
                  </div>
                  <div className={classes.arrowImg}>
                    <img
                      className={this.state.lang === "ar" ? "arabicArrow" : "arrowImage"}
                      src={
                        link.id === this.state.selectedLinkType
                          ? images.selectedArrowRight
                          : images.arrowRight
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={classes.linksList}>{getLinks}</div>
          </div>
        </div>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles: any = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    gap: "28px",
    maxWidth: "1000px",
    minHeight: "524px",
    maxHeight: "800px",
    height: "100%",
    borderRadius: "20px",
    border: "2px transparent solid",
    margin: "2rem auto",
    padding: "24px",
    justifyItems: "center",
    background:
      "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
    "@media (max-width:600px)": {
      maxWidth: "289px",
      minHeight: "592px",
      borderRadius: "10px",
      margin: "72px auto 89px",
      padding: "24px",
    },
  },
  importantLinksHeading: {
    textAlign: "center",
    color: "#5412FC",
    fontSize: "26px",
    fontFamily: "Montserrat",
    fontWeight: "700",
    lineHeight: "34px",
    wordWrap: "break-word",
    "@media (max-width:600px)": {
      fontSize: "16px",
    },
  },
  importantLinksText: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "34px",
    wordWrap: "break-word",
    textAlign: "center",
    "@media (max-width:600px)": {
      fontSize: "10px",
      lineHeight: "15px",
      margin: "30px 10px",
    },
  },
  hr: {
    border: "1px #8C8C8C solid",
    width: "100%",
  },
  linksContent: {
    width: "100%",
    display: "flex",
    margin: "0 2.5%",
    "& > div": {
      display: "flex",
      flexDirection: "column",
      gap: "32px"
    },
    "@media (max-width:600px)": {
      margin: "13px 0px",
    },
  },
  linkRow: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "@media (max-width:600px)": {
      margin: "17px 0px",
    },
  },
  linkTitle: {
    color: "#262626",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    wordWrap: "break-word",
    width: "185px",
    wordBreak: "break-word",
    "@media (max-width:759px)": {
      width: "160px",
    },
    "@media (max-width:600px)": {
      fontSize: "10px",
      width: "70px",
    },
  },
  selectedType: {
    color: "#5412FC",
    fontWeight: 700,
  },
  arrowImg: {
    minWidth: "172px",
    "& .arabicArrow": {
      rotate: "180deg",
      padding: "0px 20px" 
    },
    "@media (max-width:759px)": {
      minWidth: "100px",
    },
    "@media (max-width:600px)": {
      minWidth: "27px",
      "& img": {
        width: "12px",
      },
    },
  },
  linksList: {
    display: "flex",
    flexDirection: "column",
    gap: "32px"
  },
  linkText: {
    display: "block",
    color: "#00A3FF",
    fontSize: "18px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    textDecoration: "underline",
    wordWrap: "break-word",
    cursor: "pointer",
    wordBreak: "break-word",
    "@media (max-width:600px)": {
      marginBottom: "20px",
      fontSize: "8px",
    },
  },
};

export default withStyles(styles)(ImportantLinks);
// Customizable Area End
