import {createStyles } from "@material-ui/core";
export const Styles = () => createStyles({
    wrapper:{
      "borderRadius": "20px",
      "border": "2px solid transparent",
      "background": "linear-gradient(white, white) padding-box, linear-gradient(135deg, #00F8A2, #5412FC) border-box",
      "margin": "2rem auto",
      "width": "1000px",
      "padding" : "2%",
      "boxSizing": "border-box"
    },
    pageTitle: {
      "color": "#5412FC",
      "textAlign": "center",
      "fontFamily": "Montserrat",
      "fontSize": "28px",
      "fontStyle": "normal",
      "fontWeight": 700,
      "lineHeight": "34px",
      "marginBottom": "2%"
    },
    outerWrapper:{
      "width": "calc(100% - 100px)",
      "margin": "0 auto",
      "overflow": "hidden",
      "borderRadius": "20px",
      "border": "1px solid #8C8C8C",
      "boxSizing": "border-box",
    },
    innerWrapper:{
      "padding": "12px 24px",
      "color": "#000",
      "fontFamily": "Montserrat",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": 500,
      "lineHeight": "34px",
      "boxSizing": "border-box",
      "background": "#FFF",
      "height": "600px",
      "overflowY": "auto",
      '&::-webkit-scrollbar': {
        width: '6px',
        height:'45px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#D9D9D9', // Color of the scrollbar thumb
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: 'darkgrey', // Color of the scrollbar thumb on hover
      }
    },
    closeWrapper:{
      display: "flex",
      justifyContent: "center",
      gap: "160px",
      marginTop: "1rem"
    },
    closeButton:{
      "borderRadius": "12px",
      "border": "2px solid #10BFB8",
      "background": "#10BFB8",
      "color": "#FFF",
      "textAlign": "center",
      "fontFamily": "Montserrat",
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": 700,
      "lineHeight": "34px",
      "minWidth": "200px",
      "textTransform": "capitalize",
      "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover":{
        "background": "#10BFB8",
        "boxShadow": "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
      }
    },
    blankButton:{
      "borderRadius": "12px",
      "border": "2px solid #10BFB8",
      "background": "#fff",
      "color": "#10BFB8",
      "textAlign": "center",
      "fontFamily": "Montserrat",
      "fontSize": "20px",
      "fontStyle": "normal",
      "fontWeight": 700,
      "lineHeight": "34px",
      "minWidth": "200px",
      "textTransform": "capitalize",
      "transition": "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&:hover":{
        "background": "#fff",
        "boxShadow": "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
      }
    },
    '@media (max-width: 1220px)': {
      wrapper:{
        "width": "90%"
      }
    },
    '@media (max-width: 767px)': {
      wrapper:{
        "width": "90%",
        margin: "48px auto 50px",
        padding: "30px"
      },
      closeWrapper:{
        gap: "60px"
      },
      outerWrapper:{
        borderRadius: "10px"
      },
      innerWrapper:{
        "padding": "12px 26.5px",
        fontSize: "10px",
        lineHeight: "15px"
      },
      blankButton:{
        fontSize: "12px",
        lineHeight: "22px",
        minWidth: "106px"
      },
      pageTitle:{
        marginBottom: "30px"
      },
      closeButton:{
        fontSize: "12px",
        lineHeight: "22px",
        minWidth: "106px"
      }
    },
    '@media (max-width: 640px)': {
        closeWrapper:{
            gap: "30px",
            marginTop: "30px",
            flexDirection: "row"
        },
    }
})