Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.contentType = "application/json";
exports.apiFormContentType = "multipart/form-data";
exports.methodTypeGet = "GET";
exports.methodTypePost = "POST";
exports.methodTypePut = "PUT";
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch =
  "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.postCategoryEndPoint = "bx_block_categories/sub_categories/other_category";

exports.selectedMenuList = [
  { icon: "https://flagcdn.com/sa.svg", value: "Saudi Arabia", title: "Saudi Arabia" },
  { icon: "https://flagcdn.com/bh.svg", value: "Bahrain", title: "Bahrain" },
  { icon: "https://flagcdn.com/eg.svg", value: "Egypt", title: "Egypt" },
  { icon: "https://flagcdn.com/kw.svg", value: "Kuwait", title: "Kuwait" },
  { icon: "https://flagcdn.com/om.svg", value: "Oman", title: "Oman" },
  { icon: "https://flagcdn.com/qa.svg", value: "Qatar", title: "Qatar" },
  {
    icon: "https://flagcdn.com/ae.svg", value: "United Arab Emirates",
    title: "United Arab Emirates",
  },
];
exports.countriesData = [
  {
    icon: "https://flagcdn.com/sa.svg",
    country: 
    "Saudi Arabia",
    cities: [ "Jubail",
     "Taif", 
     "Mecca", 
     "Medina", 
     "Riyadh", 
     "Jeddah", 
     "Khobar", 
     "Dhahran", 
     "Dammam",
     "Buraydah",
    ],
    },
    {
    icon: "https://flagcdn.com/om.svg",
    country: "Oman",
    cities: ["Sohar",
    "As Suwayq",
    "Ibri",
    "Saham",
    "Barka",
    "Rustaq",
    "Muscat",
    "Seeb",
    "Salalah",
    "Bawshar"],
    },
    {
    icon: "https://flagcdn.com/ae.svg",
    country: "United Arab Emirates",
    cities: ["Dubai","Abu Dhabi","Umm Al-Quwain",
    "Ras Al Khaimah", "Sharjah","Al Ain","Ajman","Fujairah",
  ], },
    {  
    icon: "https://flagcdn.com/qa.svg",country: "Qatar",
    cities: ["Doha","Al Wakrah",
    "Umm Salal Ali","Al Rayyan",
    "Al Khor", "Mesaieed",
    "Madinat ash Shamal","Umm Salal Muhammad",
    ],     },   
    {
    icon: "https://flagcdn.com/kw.svg",country: "Kuwait",
    cities: ["Kuwait City", "Hawalli", "Salmiya","Fintas",
    "Jahra", "Sabah Al Salem","Al Ahmadi",
    "Mubarak Al-Kabeer","Farwaniya",
    ],},   
    {  
    icon: "https://flagcdn.com/bh.svg",
    country: "Bahrain",  cities:  [ "Riffa", 
    "Muharraq",
    "Hamad Town","Manama", "A'ali", "Isa Town", "Sitra",  "Budaiya",
    ],
     },
    {
    icon: "https://flagcdn.com/eg.svg",
    country: "Egypt",   
    cities: ["Cairo","Alexandria","Suez","Luxor","Asyut","Ismailia","Tanta","Giza","Shubra El Kheima","Port Said",],
     }, 
];

exports.selectedMenuListAr =  [
  {icon: "https://flagcdn.com/sa.svg",value:"المملكة العربية السعودية",title:"المملكة العربية السعودية"},
  {icon:"https://flagcdn.com/om.svg" ,value:"عُمان",title:"عُمان"},
  {icon:"https://flagcdn.com/eg.svg" ,value:"مصر",title:"مصر"},
  {icon: "https://flagcdn.com/qa.svg",value:"قطر",title:"قطر"},
  {icon: "https://flagcdn.com/ae.svg",value:"الإمارات العربية المتحدة",title:"الإمارات العربية المتحدة"},
  {icon: "https://flagcdn.com/bh.svg",value:"البحرين",title:"البحرين"},
  {icon: "https://flagcdn.com/kw.svg",value:"الكويت",title:"الكويت"}]


exports.countriesDataAr =  [
    {icon: "https://flagcdn.com/sa.svg",country:"المملكة العربية السعودية",cities:["الرياض","جدة","مكة المكرمة","المدينة المنورة","الدمام","الخبر","الظهران","الجبيل","الطائف","بريدة",]},
    {icon:"https://flagcdn.com/om.svg" ,country:"عُمان",cities:["مسقط","السيب","السويق","عبري","صحم","بركاء","الرستاق","صلالة","بوشر","صحار",]},
    {icon:"https://flagcdn.com/eg.svg" ,country:"مصر",cities:["القاهرة","الإسكندرية","الجيزة","شبرا الخيمة","بورسعيد","السويس","الأقصر","أسيوط","الإسماعيلية","طنطا",]},
    {icon: "https://flagcdn.com/qa.svg",country:"قطر",cities:["الدوحة","الوكرة","مسيعيد","مدينة الشمال","أم صلال علي","أم صلال محمد","الريان","الخور",]},
    {icon: "https://flagcdn.com/ae.svg",country:"الإمارات العربية المتحدة",cities:["دبي","أبو ظبي","الشارقة","العين","عجمان","الفجيرة","أم القيوين","رأس الخيمة",]},
    {icon: "https://flagcdn.com/bh.svg",country:"البحرين",cities:["المنامة","الرفاع","المحرق","مدينة حمد","عالي","مدينة عيسى","سترة","البديع",]},
    {icon: "https://flagcdn.com/kw.svg",country:"الكويت",cities:["مدينة الكويت","حولي","السالمية","الفروانية","الفنطاس","الجهراء","صباح السالم","الأحمدي","مبارك الكبير",]}]

exports.businessTypes = ["Retail", "Manufacturing", "Services", "Wholesale"];
exports.businessTypesAr = ["بيع بالتجـزئة", "صناعي", "خدمات", "بيع بالجـملة"];

exports.businessValidTypes = [
  "Manufacturing",
  "بالجملة",
  "Retail",
  "Services",
  "تصنيع",
  "خدمات",
  "Wholesale",
  "بيع بالتجزئة"
]; exports.realCountries = [
  { icon: "https://flagcdn.com/sa.svg", name: "Saudi Arabia", arabicName: "المملكة العربية السعودية", cities: 
    [ {name: "Jubail", arabicName: "الجبيل"},
  {name: "Taif", arabicName: "الطائف"}, 
  {name: "Mecca", arabicName: "مكة المكرمة"}, 
  {name: "Medina", arabicName: "المدينة المنورة"}, 
  {name: "Riyadh", arabicName: "الرياض"}, 
  {name: "Jeddah", arabicName: "جدة"}, 
  {name: "Khobar", arabicName: "الخبر"}, 
  {name: "Dhahran", arabicName: "الظهران"}, 
  {name: "Dammam", arabicName: "الدمام"},
  {name: "Buraydah", arabicName: "بريدة"}
 ]},
  { icon: "https://flagcdn.com/bh.svg", name: "Bahrain", arabicName: "البحرين", cities: [ 
    {name: "Riffa", arabicName: "الرفاع"},
  {name: "Muharraq", arabicName: "المحرق"},
  {name: "Hamad Town", arabicName: "مدينة حمد"},
  {name: "Manama", arabicName: "المنامة"},
   {name: "A'ali", arabicName: "عالي"},
   {name: "Isa Town", arabicName: "مدينة عيسى"},
   {name: "Sitra", arabicName: "سترة"},
    {name: "Budaiya", arabicName: "البديع"},
  ]},
  { icon: "https://flagcdn.com/eg.svg", name: "Egypt", arabicName: "مصر", cities: 
  [
    {name: "Cairo", arabicName: "القاهرة"},
  {name: "Alexandria", arabicName: "الإسكندرية"},
  {name: "Suez", arabicName: "السويس"},
  {name: "Luxor", arabicName: "الأقصر"},
  {name: "Asyut", arabicName: "أسيوط"},
  {name: "Ismailia", arabicName: "الإسماعيلية"},
  {name: "Tanta", arabicName: "طنطا"},
  {name: "Giza", arabicName: "الجيزة"},
  {name: "Shubra El Kheima", arabicName: "شبرا الخيمة"},
  {name: "Port Said", arabicName: "بورسعيد"},
]},
  { icon: "https://flagcdn.com/kw.svg", name: "Kuwait", arabicName: "الكويت", cities: [
    {name: "Kuwait City", arabicName:"مدينة الكويت"},
     {name: "Hawalli", arabicName:"حولي"},
     {name: "Salmiya", arabicName:"السالمية"},
    {name: "Fintas", arabicName:"الفنطاس"},
    {name: "Jahra", arabicName:"الجهراء"},
     {name: "Sabah Al Salem", arabicName:"صباح السالم"},
    {name: "Al Ahmadi", arabicName:"الأحمدي"},
    {name: "Mubarak Al-Kabeer", arabicName:"مبارك الكبير"},
    {name: "Farwaniya", arabicName:"الفروانية"},
  ]},
  { icon: "https://flagcdn.com/om.svg", name: "Oman", arabicName: "سلطنة عمان", cities:
[
  {name: "Sohar", arabicName: "صحار",},
    {name: "As Suwayq", arabicName: "السويق"},
    {name: "Ibri", arabicName: "عبري"},
    {name: "Saham", arabicName: "سهام"},
    {name: "Barka", arabicName: "بركه"},
    {name: "Rustaq", arabicName: "الرستاق"},
    {name: "Muscat", arabicName: "مسقط"},
    {name: "Seeb", arabicName: "السيب"},
    {name: "Salalah", arabicName: "صلالة"},
    {name: "Bawshar", arabicName: "بوشر"},
]},
  { icon: "https://flagcdn.com/qa.svg", name: "Qatar", arabicName: "قطر", cities: 
  [
    {name: "Doha", arabicName: "الدوحة"},
  {name: "Al Wakrah", arabicName: "الوكرة"},
  {name: "Umm Salal Ali", arabicName: "أم صلال علي"},
  {name: "Al Rayyan", arabicName: "الريان"},
  {name: "Al Khor", arabicName: "الخور"},
   {name: "Mesaieed", arabicName: "مسيعيد"},
  {name: "Madinat ash Shamal", arabicName: "مدينة الشمال"},
  {name: "Umm Salal Muhammad", arabicName: "أم صلال محمد"},
  ]},
  {
    icon: "https://flagcdn.com/ae.svg", name: "United Arab Emirates",
    arabicName: "الإمارات العربية المتحدة",
    cities: [
      {name: "Dubai", arabicName: "دبي" },
      {name: "Abu Dhabi", arabicName: "أبو ظبي" },
      {name: "Umm Al-Quwain", arabicName: "أم القيوين" },
    {name: "Ras Al Khaimah", arabicName: "رأس الخيمة" },
     {name: "Sharjah", arabicName: "الشارقة" },
    {name: "Al Ain", arabicName: "العين" },
    {name: "Ajman", arabicName: "عجمان" },
    {name: "Fujairah", arabicName: "الفجيرة" },
    ]
  }
]

exports.growthAppronities = [
  "Expand Physical Locations",
  "Diversify Product / Service Offerings",
  "Enter New Geographical Markets",
  "Improve Supply Chain Management",
  "Collaborate with Larger Brands",
  "Optimize Pricing Strategy",
  "Increase Advertising and Local Marketing",
  "Develop Loyalty Programs",
  "Offer Franchise Opportunities",
  "Digitalise Business",
];

exports.growthAppronitiesAr = [
  "افتتاح فروع في أماكن جغرافية جديدة",
  "تنويع المنتجات و الخدمات المقدمة",
  "التوسع لخدمة مناطق جغرافية جديدة",
  "تحسين إدارة سلسلة التوريد و الإمداد",
  "بدء شراكات مع علامات تجارية كبيرة",
  "تحسين استراتيجـية التسعير",
  "بدء شراكات مع علامات تجارية كبيرة",
  "تطوير برامج الولاء",
  "تقديم فرص امتياز تجاري لنشاط الشركة",
  "التحول الرقمي للشركة",
];

exports.growthValidAppronities = [
  "Diversify Product / Service Offerings",
  "Expand Physical Locations",
  "Improve Supply Chain Management",
  "Enter New Geographical Markets",
  "Optimize Pricing Strategy",
  "Collaborate with Larger Brands",
  "Digitalise Business",
  "Increase Advertising and Local Marketing",
  "Offer Franchise Opportunities",
  "قم بتوسيع المواقع الفعلية",
  "Develop Loyalty Programs",
  "تحسين إدارة سلسلة التوريد",
  "رقمنة الأعمال",
  "التعاون مع العلامات التجارية الأكبر",
  "تحسين استراتيجية التسعير",
  "زيادة الإعلان والتسويق المحلي",
  "تطوير برامج الولاء",
  "تقديم فرص الامتياز",
  "دخول الأسواق الجغرافية الجديدة",
  "تنويع عروض المنتجات/الخدمات"
];

exports.listingStatusEn = {
  "accepted": "Accepted",
  "rejected": "Rejected",
  "under_review": "Under Review"
}

exports.listingStatusAr = {
  "accepted": "قبلت",
  "rejected": "مرفوض",
  "under_review": "قيد المراجعة"
}
exports.keyAssests = [
  "Physical Assets (Buildings, Lands)",
  "Inventory Stock",
  "Machinery and Equipment",
  "Business License / Permit",
  "Established Brand Name",
  "Trained Staff and Employees",
  "Existing Customer Base",
  "Supplier Contracts and Relationships",
  "Local and/or International Distribution Channels",
  "Proprietary Recipes / Methods",
];

exports.keyAssestsAr = [
  " أصول مادية (مباني, أراضي...)",
  "مخزون",
  "أجهزة و آلات",
  "تصريح خاص للشركة",
  "بدء شراكات مع علامات تجارية كبيرة",
  "فريق عمل مدرب بكفاء عالية",
  "قاعدة العملاء الحالية",
  "اسم علامة تجارية قوي",
  "قنوات توزيع محلية و عالمية",
  "وصفات خاصة / منهجيات خاصة",
];

exports.keyValidAssests = [
  "Inventory Stock",
  "Physical Assets (Buildings, Lands)",
  "Business License / Permit",
  "Established Brand Name",
  "Machinery and Equipment",
  "Existing Customer Base",
  "Trained Staff and Employees",
  "Local and/or International Distribution Channels",
  "Supplier Contracts and Relationships",
  "Proprietary Recipes / Methods",
  "الأصول المادية (المباني والأراضي)",
  "مخزون المخزون",
  "آلات ومعدات",
  "رخصة / تصريح تجاري",
  "اسم العلامة التجارية المنشأة",
  "طاقم عمل وموظفون مدربون",
  "قاعدة العملاء الحالية",
  "عقود الموردين والعلاقات",
  "قنوات التوزيع المحلية و/أو الدولية",
  "وصفات / طرق خاصة"
];
// Company Profile

exports.navPositions = {
  summery: 230,
  overview: 80,
  acquasition: -150,
  financials: -270,
  "customer-matrices": -420
};

exports.menuList = [
  {
    component: "summery",
    label: "Summary",
    class: "active",
  },
  {
    component: "overview",
    label: "Company Overview",
    class: "",
  },
  {
    component: "acquasition",
    label: "Acquisition Details",
    class: "",
  },
  {
    component: "financials",
    label: "Financials",
    class: "",
  },
  {
    component: "customer-matrices",
    label: "Customers Metrices",
    class: "",
  },
];
exports.successMessageToastText="Details updated successfully";
exports.requestAccess = "Request start up access";
exports.requestAccess_title =
  "Become a Nami Premium Buyer and <br>request access to countless promising <br>oppurtunities!";
exports.requestAccess_subTitle =
  "To view this startup's private details and chat with the founder, please purchase <br>a subscription plan";
exports.requestAccessBtn1 = "Be a Premium Buyer";
exports.requestAccessBtn2 = "Continue Exploring";

exports.ndaTitle = "NDA Required";
exports.ndaSubTitle =
  "To be able to send a request to a seller,<br/> you would have to sign the NDA";
exports.ndaDetails =
  "To view this startup's private details and chat with the founder, please sign the NDA.<br/>The NDA needs to be signed by both parties for your request to be considered.";
exports.ndaBtn1 = "View NDA";

exports.successTitle = "Success!";
exports.successSubTitle =
  "Your Request to view their entire listing <br/>has been sent to the seller";
exports.successDetails =
  "To view this startup's private details and chat with the founder, request access below.<br/>You must sign an NDA to request access to this startup";
exports.successBtn1 = "View Request";
exports.successBtn2 = "Explore Listings";

exports.sign = "Signature";
exports.agree = "Agree";
exports.disagree = "Disagree";
exports.save = "Save";
exports.clear = "Clear";

exports.sellerTxt = "seller";
exports.buyerTxt = "buyer";
exports.getUserEndpoint = "bx_block_profile/start_ups/buyer_profile";

exports.usersListApiEndpoint = "bx_block_catalogue/listings/user_list";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getCatalogueAPIEndPoint = "bx_block_catalogue/catalogues";
exports.getCategoriesAPIEndPoint = "bx_block_categories/categories";
exports.createSmeApiEndpoint = "bx_block_catalogue/listings/create_listing";
exports.getSubCategoriesEndPoint = "bx_block_categories/sub_categories";
exports.getUserProfileEndPoint = "account_block/accounts"
exports.updateCustomerMetricApiEndpoint = "bx_block_profile/business_profiles/update_business_customer_vendor_metrics";
exports.updateFinancialApiEndpoint = "bx_block_profile/business_profiles/update_business_financials";
exports.updateSummarryApiEndpoint = "bx_block_profile/business_profiles/update_summarry";
exports.updateCompanyOverViewApiEndpoint = "bx_block_profile/business_profiles/update_business_company_overview";
exports.updateAcquisitionDetailApiEndpoint = "bx_block_profile/business_profiles/update_business_acquisition_details";
exports.updateRpivateInformationApiEndpoint = "bx_block_profile/business_profiles/update_private_business_information";
exports.getPrivateInformationApiEndpoint = "bx_block_profile/business_profiles/show_private_business_information"
exports.getSingleSmeEndPoint = "/bx_block_catalogue/listings/get_list";
exports.updateListPercentage = "bx_block_catalogue/listings/update_list";

exports.getAccessCountEndpoint = "account_block/access_requests/total_this_month";
exports.getBusinessDetailEndpoint = "bx_block_catalogue/listings";
exports.createAccessRequestEndpoint = "account_block/access_requests";
exports.getUserProfileRoleEndPoint = "account_block/accounts/show_roles"

exports.Reason_for_Selling = "Reason for Selling";
exports.Financial_Methods = "Financial Methods";
exports.errorMessage = "This is required question";
exports.alphaNumericError = "This field required only numbers";
exports.errorMessageUploadDoc = "Please upload document";
exports.keyFinancial = {
  self_funded_text: "Self Funded",
  corporate_funded_text: "Corporate Funded",
  shareholders_equity_funded_text: "Shareholder Equity Funded",
  loans_text: "loans",
};
exports.addNewListingText="Add New Listing";
exports.addNewListingText_ar="إضافة قائمة جديدة";


exports.addingNewListingCompleteText="Adding a new listing is activated after your current listing is 100% Complete";
exports.addingNewListingCompleteText_ar=" إضافة قائمة جديدة سيكون متاحا عند استكمال بيانات القائمة الحالية بنسبة 100%";
exports.otherCategoryText = "Other category"
exports.otherLabel = "Other Business Type"
exports.privateInformationHeadingText="Private Information";
exports.subHeadingLoremIpsumText="This information will be shared with premium Nami buyers if a non-disclosure agreement is agreed between you and the buyer";
exports.summaryHeadingText="Summary";
exports.summaryHeadingText_ar="المعلومات العامة";
exports.commonHeadingText="This information will be available to registered Nami Users";
exports.companyOverViewText="Company Overview";
exports.companyOverViewText_ar="نظرة عامة عن الشركة";

exports.acquisationDetailsHeading="Acquisition Details";
exports.acquisationDetailsHeading_ar="معلومات الاستحواذ";

exports.financialHeadingText="Financials";
exports.financialHeadingText_ar="المعلومات المالية";

exports.customerAndVenderMetrics="Customer & Vendor Metrics";
exports.customerAndVenderMetrics_ar="مقياس العملاء و البائعين";

exports.uploadDocumentText="Upload document";
exports.uploadDocumentText_ar="تحميل وثيقة";

exports.titleText="Title :";
exports.startUpText="Start Up";
exports.startUpText_ar="شركة ناشئة";

exports.businessText=" Business";
exports.businessText_ar="عمل";

exports.NextbuttonText="Next";
exports.NextbuttonText_ar="التالي";
exports.successText="Success!";
exports.successText_ar="نجاح!";


exports.listingAddedText="Your listing has been successfully added!";
exports.listingAddedText_ar="تمت إضافة الشركة بنجاح!"

exports.successConfirmTextLine1="Your application has been successfully submitted.";
exports.successConfirmTextLine1_ar="تم تقديم طلبك بنجاح.";

exports.successConfirmTextLine2 = "We will review your application and get back to you shortly.";
exports.successConfirmTextLine2_ar = "سوف نقوم بمراجعة طلبك و العودة إليك قريبا";

exports.successConfirmTextLine4 =  "Your Listing ID is : ";
exports.successConfirmTextLine4_ar = "الرمز الخاص بقائمتك: ";


exports.successConfirmTextLine3 = "Your application ID is: "
exports.youCanAccessText="You can access it from the seller homepage";
exports.youCanAccessText_ar="يمكن الوصول للقائمة من الصفحة الرئيسية";

exports.currency="SAR";
exports.currency_ar=" ر.س";
exports.companyNameInputLabel = "Company Name :";
exports.companyWebsiteInputLabel = "Company Website :";
exports.vendorsInputLabel = "How many vendors or suppliers you currently work with?: ";
exports.vendorsInputLabel_ar="كم عدد الموردين المتعاقد معهم حاليا؟";
exports.yesLabel = "Yes";
exports.noLabel = "No";
exports.commitmentsInputLabel = "Do you have any ongoing contracts or commitments with clients or vendors? :";
exports.commitmentsInputLabel_ar="هل لديك حاليا عقود سارية مع عملاء أو موردين؟";
exports.contractInputLabel = "Attach the current contracts with clients and suppliers:";
exports.contractInputLabel_ar ="أرفق العقود القائمة حاليا مع العملاء و الموردين (اختياري)";
exports.pAndLInputLabel = "Attach the company's income statement: ";
exports.balanceSheetInputLabel = "Attach the company's balance sheet: ";
exports.documentsFilesInputLabel = "Documents & Files :";
exports.totalDeptInputLabel = "Total Dept :";
exports.totalAssetsInputLabel = "Total Assets :";
exports.totalShareholderEquityInputLabel = "Total Shareholder Equity :";
exports.autoSignInputLabel = "Auto Sign :";
exports.autoSignInputLabel_ar = "التوقيع التلقائي:";

exports.autoSignNdaLabel = "Auto Sign NDA";
exports.autoSignNdaLabel_ar = "التوقيع التلقائي لإتفاقية عدم الإفصاح";

exports.autoAcceptRequestLabel = "Auto Accept Request";
exports.autoAcceptRequestLabel_ar = "قبول الطلبات تلقائيا";

exports.viewFullProfileText="View full profile";
exports.progressCompleteLabel = "% Complete";
exports.yourListingLabel = "Your listing";
exports.completeProfileText = "Complete your profile to get verified and secure your spot on the marketplace";
exports.needHelpText = "Need help with this?";
exports.completeProfileButtonLabel = "Complete your profile";
exports.exploreListingsButtonLabel = "Explore Listings";
exports.clearText="Clear";
exports.clearText_ar="حذف";
exports.saveText="Save";
exports.saveText_ar="حفظ";
exports.dateFoundedInputLabelText = "Date Founded: ";
exports.employeesInputLabelText = "Total Number of Employees: ";
exports.businessTypeInputLabelText = "Business Type / Category: ";
exports.countryInputLabelText = "Country: ";
exports.cityInputLabelText = "City: ";
exports.headlineInputLabelText = "Headline: ";
exports.otherCatText="Other category name:";

exports.descriptionInputLabelText = "Description: ";
exports.askingPriceInputLabelText = "Asking Price: ";
exports.businessTypeInputLabel = "Business type: ";
exports.listOfProductsInputLabel = "List of Products / Services :";
exports.revenueModelInputLabel = "What is your revenue model? (e.g., Sales, Subscription, Licensing, Franchise): ";
exports.revenueModelInputLabel_ar = " ما هو النموذج الربحي للشركة/مصادر الإيرادات؟ (مبيعات، اشتراكات، ترخيص، امتياز تجـاري،....)";

exports.regionsMarketInputLabel = "Define the geographical scope of your company: ";
exports.numberOfSuppliersInputLabel = "What is the number of your main suppliers? : ";
exports.competitorsInputLabel = "Who are your competitors? : ";
exports.competitorsInputLabel_ar = "ما هي الشركات المنافسة لك:";

exports.growthOpportunityInputLabel = "Growth Opportunity : ";
exports.keyAssetsInputLabel = "Key Assets : ";
exports.askingPriceReasoningInputLabelText = "Rationale for the asking price: ";
exports.reasonForSellingLabelText="Reason for Selling :";
exports.financingSoucesLabelText="Financing Sources :";
exports.selfFundedCheckboxLabel= "Self Funded";
exports.corporateFundedCheckboxLabel= "Corporate Funded";
exports.shareholdersEquityCheckboxLabel= "Shareholders Equity";
exports.shareholdersEquityCheckboxLabel_ar= "تمويل الشركاء/ المستثمرين";

exports.loansCheckboxLabel= "Loans";
exports.otherCheckboxLabel="Other";
exports.ltmGrossRevenueInputLabel="LTM Gross Revenue :";
exports.ltmGrossRevenueTooltip = "Enter the total amount of money your company has made from sales over the last twelve months before deducting any expenses.";
exports.ltmNetProfitInputLabel = "LTM Net Profit :";
exports.ltmNetProfitTooltip = "Enter the amount of money that remains from your gross revenue after subtracting all the costs and expenses over the last twelve months.";
exports.annualRecurringRevenueInputLabel = "Annual Recurring Revenue :";
exports.annualRecurringRevenueTooltip = "Enter the amount of money that your company expects to regularly receive every year from things like subscriptions or ongoing services.";
exports.annualGrowthRateInputLabel = "Annual Revenue Growth vs. Last Year:";
exports.annualGrowthRateInputLabel_ar = "معدل نمو المبيعات السنوي مقارنة بالسنة السابقة:";

exports.annualGrowthRateTooltip = "Enter the percentage that your company's revenue has increased compared to the previous year. Do not include any negative numbers, even if your company didn't grow.";
exports.revenueMultipleInputLabel = "Revenue multiple (total company value/revenue) :";
exports.revenueMultipleInputLabel_ar = " معامل الإيرادات (إجمالي قيمة الشركة\\الإيرادات):";

exports.revenueMultipleTooltip = "3-10x revenue, high for tech startups due to growth potential.";
exports.priceEarningsRatioInputLabel = "Price/Earnings multiple (Equity value / Net profit) :";
exports.priceEarningsRatioInputLabel_ar = `معامل السعر\\الأرباح (قيمة الأسهم\\صافي الربح):`;

exports.priceEarningsRatioTooltip = "Price / Earnings";
exports.howManyCustomersInputLabel="How many customers or clients do you currently serve? :";
exports.howManyCustomersInputLabel_ar="ما عدد عملاؤك الحاليين الذين تقوم بخدمتهم؟";

exports.startupContentText="Tech-oriented businesses with innovative ideas aiming for rapid growth by addressing a unique market need (e.g., An online food delivery platform)";
exports.startupContentText_ar ="الشركات الموجهة نحو التكنولوجيا والتي تتمتع بأفكار مبتكرة تهدف إلى النمو السريع من خلال تلبية احتياجات السوق الفريدة (على سبيل المثال، منصة توصيل الطعام عبر الإنترنت)";

exports.businessContentText="An established entity that offers products or services i.e. manufacturing, trading, brokerage, services etc. (e.g., A family-owned fashion retail brand)";
exports.businessContentText_ar ="كيان مؤسس يقدم منتجات أو خدمات مثل التصنيع والتجارة والوساطة والخدمات وما إلى ذلك (على سبيل المثال، علامة تجارية لبيع الأزياء بالتجزئة مملوكة لعائلة)";

exports.getDealsStatusEndPoint = "/account_block/access_requests/buyer_deals";
exports.under_review = "#8C8C8C"
exports.accepted = "#10BFB8"
exports.rejected = "#FF7575"
exports.invaildField = "Required: Only letters and digits allowed"
exports.knowMoreText = "Interested to know more?";
exports.becomeBuyerText = "Become a Buyer";
exports.knowMoreTextAr = "هل ترغب بمعرفة المزيد؟";
exports.becomeBuyerTextAr = "كن مشتريًا";
exports.youBuilderText = "www.youbuilder.com";
exports.becomePremium = "Become a Premium Nami member!";
exports.becomePremiumAr = "أنضم إلى نامي بريميوم!";
exports.nameRegex = /^[a-zA-Z\u0600-\u06FF0-9\s]+$/;

exports.userProfileEn = {
  fullProfile: "Full Profile",
  editProfile: 'Edit Profile',
  fullProfileInfo: "Full Profile Information",
  profilePicture: "Profile Picture",
  uploadPhoto: "Upload Photo",
  firstName: "First Name:",
  lastName: "Last Name:",
  gender: "Gender:",
  dob: "Date of Birth:",
  selectRole: "Role:",
  min: 'Please enter minimum 3 characters',
  linkedIn: "LinkedIn Profile Link:",
  selectCountry: "Select Country:",
  selectCity: "Select City:",
  aboutYourself: "About your company:",
  noOf: "Number of Acquisitions Closed:",
  pro: "Projected Annual Acquisitions:",
  ver: "Verify Identity:",
  upl: " Upload Document (National ID / Passport)",
  acc: "Are you an Accredited Buyer?",
  save: "Save",
  clear: "Clear",
  preferredLang: 'Preferred Language:',
  success: 'Your profile has been updated successfully!',
  backBtn: "Back to my acquisition",
  backBtnB: "Back to acquisition process",
  down: "Download your document",
  pefn: 'Please enter first name',
  twenty: 'Please enter maximum 20 characters',
  max: 'Please enter maximum value 999999',
  two: 'Please enter maximum 200 characters',  
  lName: 'Please enter last name',
  lUrl: 'Please enter valid linkedin url'

}

exports.userProfileAr = {
  fullProfile: "الملف الشخصي الكامل",
  editProfile: 'تعديل الملف الشخصي',
  fullProfileInfo: "معلومات الملف الشخصي الكاملة",
  profilePicture: "صورة الملف الشخصي",
  uploadPhoto: "تحميل الصورة",
  firstName: "الاسم الأول:",
  lastName: "اسم العائلة:",
  gender: "الجنس:",
  dob: "تاريخ الميلاد:",
  selectRole: "اختر الدور:",
  linkedIn: "رابط صفحة لنكدان:",
  selectCountry: "الدولة:",
  selectCity: "المدينة:",
  aboutYourself: "نبذة عن شركتك:",
  noOf: "عدد عمليات الاستحواذ المغلقة:",
  pro: "عمليات الاستحواذ السنوية المتوقعة:",
  ver: "التحقق من الهوية:",
  upl: "رفع الوثيقة (الهوية الوطنية / جواز السفر)",
  acc: "هل أنت مشترٍ معتمد؟",
  save: "حفظ",
  clear: "حذف",
  preferredLang: "اللغة المفضلة:",
  success: "تم تحديث ملفك الشخصي بنجاح!",
  backBtn: "العودة إلى عملية الاستحواذ",
  two: 'الرجاء إدخال 200 حرف كحد أقصى',
  success: "تم تحديث ملفك الشخصي بنجاح!",
  down: "تنزيل المستند",
  min: `يرجى إدخال 3 أحرف كحد أدنى`,
  pefn: 'الرجاء إدخال الاسم الأول',
  twenty: 'يرجى إدخال 20 حرفاً كحد أقصى',
  max: 'الرجاء إدخال القيمة القصوى 99999999',
  lName: 'الرجاء إدخال اسم العائلة',
  backBtnB: "العودة إلى عملية الاستحواذ",
  lUrl: "يُرجى إدخال رابط لينكد إن صالح"
}

exports.pageText = {
  employeeNumber: "Number of employees:",
  founded: "Founded in:",
  category: "Category:",
  basedIn: "Based in:",
  aboutSeller: "About Seller",
  askingprice: "Asking Price",
  plStatement: "P & L Statement :",
  documents: "Documents",
  selfFund: "Self Funded ",
  docFiles: "Documents & Files :",
  balanceSheet: "Balance Sheet :",
  aboutVendor: "About suppliers and vendors",
  role: "Role",
  ongoingContract: "Do you have any ongoing contracts or commitments with clients or vendors? :",
  vendorList: "List of suppliers / vendors :",
  isNo: "No",
  isYes: "Yes",
  financials: "Financials",
  vendorContract: "Contract with suppliers and vendors:",
  financialMethod: "Financial Methods",
  mainSupplier: "Main Suppliers",
  incubator: "Incubator / Accelerators ",
  equity: "Shareholders Equity ",
  totalAssets: "Total Assets :",
  totalDebt: "Total Debt :",
  loan: "Loans ",
  sellReason: "Reason for Selling",
  totalEquity: "Total Shareholder Equity :",
  vcBacked: "VC Backed ",
  corpFund: "Corporate Funded ",
  mainCompititors: "Main competitors",
  revenueModel: "Revenue Model",
  businessType: "Business Type",
  primaryProd: "Primary Products or Services",
  growthOppo: "Growth Opportunities",
  regionMarket: "Operatinal Region or Market",
  multiNumber: "Multiple Number",
  keyAsset: "Key Assets",
  netProfit: "LTM Net Profit",
  grossRevenue: "LTM Gross Revenue",
  growthRate: "Annual Growth Rate",
  annualRev: "Annual Recurring Revenue",
  customerMetrics: "Customer Metrics",
  originalListing: "Original listing in ",
  multipleType: "Multiple Type",
  accessReqs: "Access Requests",
  arabic: "Arabic",
  reqAccessDone: "Access Requested",
  youHave: "You have ",
  english: "English",
  findIntresting: "Find this listing intresting?",
  competitors: "Competitors",
  resetDay: " Days till reset",
  leftReq: " requests left this week",
  viewSimilar: "View Similar",
  explore: "Explore Listings",
  canReq: "Cancel Request",
  success: "Success!",
  yesCancel: "Yes, Cancel my request",
  successDesc: "To view this startup's private details and chat with the founder, request access below. You must sign an NDA to request access to this startup",
  successSubtitle: "Your Request to view their entire listing has been sent to the seller",
  cancelSubtitle: "You have successfully canceled access request for this listing",
  reqAccess: "Request Access",
  viewReq: "View Request",
  cancleConfirm: "Are you sure you want to cancel access request for this listing, this will result in having no access to the listing deatils",
  ndaDescription: "To view this startup's private details and chat with the founder, please sign the NDA. The NDA needs to be signed by both parties for your request to be considered.",
  ndaTitle: "NDA Required",
  sureTxt: "Are You Sure?",
  noBack: "No, take me back",
  viewNda: "View NDA",
  ndaSubTitle: "To be able to send a request to a seller, you would have to sign the NDA",
  viewList: "View this listing"
};

exports.pageTextAr = {
  viewSimilar: "عرض مماثل",
  isNo: "لا",
  ndaTitle: "اتفاقية عدم الإفصاح عن الهوية مطلوبة",
  viewNda: "عرض اتفاقية عدم الإفصاح",
  yesCancel: "نعم، قم بإلغاء طلبي",
  cancleConfirm: "هل أنت متأكد من رغبتك في إلغاء طلب الوصول إلى هذه القائمة، سيؤدي ذلك إلى عدم إمكانية الوصول إلى تفاصيل القائمة",
  cancelSubtitle: "لقد قمت بإلغاء طلب الوصول إلى هذه القائمة بنجاح",
  sureTxt: "هل أنت متأكد؟",
  noBack: "لا، أعدني إلى الوراء",
  successDesc: "لعرض التفاصيل الخاصة بهذه الشركة الناشئة والدردشة مع مؤسسها، اطلب الدخول أدناه. يجب عليك التوقيع على اتفاقية عدم الإفصاح لطلب الوصول إلى هذه الشركة الناشئة",
  ndaDescription: "للاطلاع على التفاصيل الخاصة بهذه الشركة الناشئة والدردشة مع مؤسسها، يرجى التوقيع على اتفاقية عدم الإفصاح. يجب أن يوقّع كلا الطرفين على اتفاقية عدم الإفشاء حتى يتم النظر في طلبك.",
  ndaSubTitle: "لكي تتمكن من إرسال طلب إلى البائع، يجب عليك التوقيع على اتفاقية عدم الإفصاح",
  vendorContract: "التعاقد مع الموردين والبائعين:",
  ongoingContract: "هل لديك أي عقود أو التزامات مستمرة مع العملاء أو البائعين؟ :",
  founded: "تاريخ التأسيس:",
  basedIn: "مقرها في:",
  category: "الفئة:",
  askingprice: "السعر المطلوب",
  aboutSeller: "حول البائع",
  documents: "وثائق",
  vendorList: "قائمة الموردين / البائعين :",
  balanceSheet: "ورقة التوازن :",
  docFiles: "المستندات والملفات :",
  role: "دور",
  aboutVendor: "عن الموردين والبائعين",
  isYes: "نعم",
  financials: "المالية",
  totalDebt: "الدين الكلي :",
  totalAssets: "إجمالي الأصول :",
  totalEquity: "إجمالي حقوق المساهمين :",
  plStatement: "بيان الربح والخسارة :",
  sellReason: "سبب البيع",
  financialMethod: "الأساليب المالية",
  loan: "القروض ",
  selfFund: "التمويل الذاتي ",
  corpFund: "تمويل الشركات ",
  equity: "حقوق المساهمين ",
  vcBacked: "مدعومة برأس المال الاستثماري ",
  incubator: "الحاضنة / المسرعات ",
  businessType: "نوع العمل",
  revenueModel: "نموذج الإيرادات",
  mainSupplier: "الموردين الرئيسيين",
  regionMarket: "منطقة التشغيل أو السوق",
  primaryProd: "المنتجات أو الخدمات الأساسية",
  arabic: "العربية",
  growthOppo: "فرص متنامية",
  mainCompititors: "المنافسون الأساسيون",
  keyAsset: "الأصول الرئيسية",
  grossRevenue: "إجمالي الإيرادات LTM",
  netProfit: "صافي الربح LTM",
  annualRev: "الإيرادات السنوية المتكررة",
  growthRate: "معدل النمو السنوي",
  multipleType: "نوع متعدد",
  leftReq: " الطلبات تركت هذا الأسبوع",
  multiNumber: "رقم متعدد",
  customerMetrics: "مقاييس العملاء",
  accessReqs: "طلبات الوصول",
  reqAccessDone: "تم طلب الوصول",
  reqAccess: "طلب الدخول",
  youHave: "لديك ",
  resetDay: "أيام حتى إعادة التعيين",
  competitors: "المنافسون",
  originalListing: "القائمة الأصلية باللغة ",
  english: "إنجليزي",
  findIntresting: "تجد هذه القائمة مثيرة للاهتمام؟",
  employeeNumber: "عدد الموظفين:",
  viewReq: "عرض الطلب",
  explore: "استكشف القوائم",
  canReq: "إلغاء الطلب",
  success: 'نجاح!',
  successSubtitle: "تم إرسال طلبك لعرض القائمة بالكامل إلى البائع",
  viewList: 'عرض هذه القائمة'
};

exports.menuListAr = [
  {
    component: "summery",
    class: "active",
    label: "ملخص"
  },
  {
    label: "نبذة عن الشركة",
    component: "overview",
    class: ""
  },
  {
    label: "تفاصيل الاستحواذ",
    component: "acquasition",
    class: ""
  },
  {
    label: "المالية",
    component: "financials",
    class: ""
  },
  {
    label: "مقاييس العملاء",
    component: "customer-matrices",
    class: ""
  },
];
exports.unBookmarkApiEndPoint = "bx_block_catalogue/update_bookmark_catalogue";
exports.bookmarkListEndPoint = "bx_block_catalogue/catalogues_lists?list=list";
exports.bookmarkModalTitle = "Add to list...";
exports.addListTxt = "Create New List";
exports.bookmarkEndPoint = "bx_block_catalogue/create_bookmark";
exports.reportApiEndPoint = "bx_block_catalogue/archieve_and_report";
exports.chooseThisWillText="Choosing this will";
exports.chooseThisWillTextArabic = "هذا الإختيار سوف ";
exports.flagBoxDetails = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";
exports.flagBoxDetailsArabic = "هل تواجه مشكلة في هذه القائمة؟ قم بالإبلاغ عنها لمساعدتنا في الحفاظ على الجودة، أو قم بأرشفتها للحفاظ على لوحة التحكم الخاصة بك واستعادتها بسهولة في وقت لاحق إذا لزم الأمر."
exports.flagTheListing = "flag the listing";
exports.flagTheListingArabic = "يضيف القائمة للأرشيف";
exports.reportBlogTitle = "Why are you reporting this listing?";
exports.shareBoxTitle = "Share this listing";
exports.yesText="Yes";
exports.yesTextArabic = "نعم";
exports.noText="No";
exports.noTextArabic = "لا";
exports.flagBoxTitle = "Problem with this listing?";
exports.flagBoxTitleArabic = "هل هناك مشكلة في هذه القائمة؟";
exports.archiveListingText="Are you sure you want to archive this listing?";
exports.archiveListingTextArabic= "هل أنت متأكد من إضافة القائمة للأرشيف؟";
exports.reportText ="Report";
exports.reportTextArabic = "إبلاغ";
exports.ArchiveText="Archive";
exports.ArchiveTextArabic = "أرشفة";
exports.hideTheListingText="hide the listing";
exports.hideTheListingTextArabic = "يخفي القائمة";
exports.flagReportEndPoint = "bx_block_catalogue/reports";
exports.cancelRequestEndPoint = "account_block/access_requests/{accessId}/cancel";
exports.selectFeilds = "Selected File: "

// Customizable Area End

