import React from "react";
import { Box, Button, Grid, Typography, withStyles, Dialog, createTheme  } from "@material-ui/core";
import BuyerSubscriptionController, {
  configJSON,
} from "./BuyerSubscriptionController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { Styles } from "./Styles.web";
import {
  activeIcon,
  alertIcon,
  card1Icon,
  card2Icon,
  card3Icon,
  card4Icon,
  card5Icon,
  card6Icon,
  card7Icon,
  card8Icon,
  expiredIcon,
} from "./assets";
const assets = require("./assets");
//Customizable Area Start
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { ThemeProvider, styled } from "@material-ui/styles";
//Customizable Area End

export class BuyerSubscription extends BuyerSubscriptionController {
  render() {
    //Customizable Area Start
    function formatExpirationDate(expirationDate: any) {
      const expDate = new Date(expirationDate);

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const day = expDate.getDate();
      const monthIndex = expDate.getMonth();
      const year = expDate.getFullYear();

      // Format the date
      const formattedDate = `${day} ${months[monthIndex]}, ${year}`;

      return formattedDate;
    }
    const { classes } = this.props;
    const featureList = () => {
      return this.state.feature.map((item: any, index: any) => {
        return (
          <Box className={classes.featureBlock} key={index}>
            <img src={item.image_url} alt="" />
            <Box className={classes.featureBlockDetails}>
              <Typography component="h1" className={classes.featureTitle}>
                {item.name}
              </Typography>
              <Typography component="p" className={classes.featureDetails}>
                {item.description}
              </Typography>
            </Box>
          </Box>
        );
      });
    };

    const activeAndExpiredCard = () => {
      const {
        remaining_days,
        expiration_date,
        subscription_status,
      } = this.state;
      

      return (
        <Box>
          <Box
            className={this.getIfConditionStatement(subscription_status === "active", classes.activeBorder, classes.expiredBorder)
            }
          >
            <Box className={classes.activeIcon}>
              <Box className="">
                <img
                  src={
                    subscription_status === "active" ? activeIcon : expiredIcon
                  }
                  alt=""
                />
              </Box>
              <Typography>
                {subscription_status === "active" ? this.state.pageTxtData.active : this.state.pageTxtData.expired}
              </Typography>
            </Box>
            <Box className={classes.plan}>
              <Grid container spacing={6}>
                <Grid item md={8}>
                  <Typography variant="h3" component="h3" className="planName">
                    {this.state.featureName}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Box className={classes.days}>
                    <Typography
                      variant="h3"
                      component="h3"
                      className="planName"
                    >
                      {remaining_days ? remaining_days : "-"}
                    </Typography>
                    <Box>
                      <Typography component="p">{this.state.pageTxtData.days}</Typography>
                      <Typography component="p">{this.state.pageTxtData.remaining}</Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={8}>
                  <Typography component="p">
                    {this.state.pageTxtData.accessToken}
                  </Typography>
                  <Typography variant="h4" component="h4">
                    {this.state.total_token}
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography component="p">
                  {this.state.pageTxtData.expiresOn}
                  </Typography>
                  <Typography variant="h4" component="h4">
                    <span className="arabicDate">x</span>
                    {formatExpirationDate(expiration_date)}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box>
            {this.getIfConditionStatement(subscription_status === "active", <>
              <Box className={classes.ActiveFeatureListWrapper}>
                <Typography
                  component="h4"
                  variant="h4"
                  className={classes.ActiveFeatureListTitle}
                >
                  {this.state.pageTxtData.whatYouGet}
                </Typography>
                <Box className={classes.ActiveFeatureList} style={{marginBottom: '0px'}}>
                  {this.state.userSubscribed_feature_benefits.map(
                    (item: any, index: any) => {
                      return (
                        <Box className={classes.featureBlock} key={index}>
                          <img src={item.image_url} alt="" />
                          <Box className={classes.featureBlockDetails}>
                            <Typography
                              component="h1"
                              className={classes.featureTitle}
                            >
                              {this.getSpecifiedLangString(this.state.language,item.name,item.arabic_name)}
                            </Typography>
                            <Typography
                              component="p"
                              className={classes.featureDetails}
                            >
                              {this.getSpecifiedLangString(this.state.language,item.description,item.arabic_description)}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Box>
                
              </Box>
              <Box className={classes.footersubscribe_wrapper}>
                <Button
                  data-testid="subscribedBtn"
                  disabled
                  className={classes.subscribeButton}
                  style={{border: 'rgb(140, 140, 140)', background: 'rgb(140, 140, 140)', color: 'white'}}
                >
                  {this.state.pageTxtData.subscribedBtnTxt}
                </Button>
              </Box>
              </>, <Box className={classes.OuterRenewCard}>
                <Box className={classes.renewCard}>
                  <Typography component="h1" className={classes.pageTitle}>
                    {this.state.pageTxtData.expiredText}
                  </Typography>
                  <Box className={classes.alertDiv}>
                    <Box>
                      <img src={alertIcon} alt="" />
                    </Box>
                    <Typography component="h4" variant="h4">
                      {this.state.pageTxtData.doYouWish}
                    </Typography>
                  </Box>
                  <Typography component="p" className={classes.alertPara}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla sodales metus a tristique gravida. Mauris mi augue,
                    fermentum eget accumsan eget, hendrerit eu neque.
                  </Typography>
                  <Box className={classes.expiredActions}>
                    <Button
                      data-testid="cancelButton"
                      className={classes.cancelButton}
                    >
                      {this.state.pageTxtData.takeMeBack}
                    </Button>
                    <Button
                      data-testid="renewButton"
                      className={classes.subscribeButton}
                      onClick={this.buyerSubscribe}
                    >
                      {this.state.pageTxtData.renewSub}
                    </Button>
                  </Box>
                </Box>
              </Box>)}
          </Box>
        </Box>
      );
    };

    const SubscriptionDetails = () => {
      return (
        <>
          <Typography component="h1" className={classes.pageTitle}>
            {this.state.pageTxtData.subscriptions}
          </Typography>
          <Typography component="p" className={classes.pageDetails}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.pageTxtData.buyerSubscribeDetails,
              }}
            ></div>
          </Typography>
          <Box className={classes.outersubscribe_wrapper}>
            <Box className={classes.innersubscribe_wrapper}>
              <Box className={classes.heading}>
                <Typography component="h2" className={classes.title}>
                  {this.state.featureName}
                </Typography>
                <Typography component="span" className={classes.priceLabel}>
                  {configJSON.currencyTxt} {this.state.featurePrice}/{" "}
                  <Typography component="span">{this.state.pageTxtData.anually}</Typography>
                </Typography>
              </Box>
              <Typography component="h3" className={classes.childHeading}>
                {this.state.pageTxtData.childHeading}
              </Typography>
              <Box className={classes.featureList}>{featureList()}</Box>

              <Box className={classes.footersubscribe_wrapper}>
                <Button
                  data-testid="subscribeBtn"
                  onClick={this.buyerSubscribe}
                  className={classes.subscribeButton}
                >
                  {this.state.pageTxtData.subscribeBtnTxt}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      );
    };


    const PaymentForm: any = () => {
      const script = document.createElement('script');
      script.src = "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId="+this.state.checkoutId;
      script.async = true;
      script.onload = () => {
      };
      document.body.appendChild(script);
      
      return (
          <Box className={classes.paymentBorder}>
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Box className={classes.cardAccept}>
                  <Typography
                    component="h4"
                    variant="h4"
                    className={classes.ActiveFeatureListTitle}
                    align="center"
                  >
                    {this.state.pageTxtData.weAcceptFollowingCards}
                  </Typography>
                  <ul>
                    {[
                      card1Icon,
                      card2Icon,
                      card3Icon,
                      card4Icon,
                      card5Icon,
                      card6Icon,
                      card7Icon,
                      card8Icon,
                    ].map((items: any, index: any) => {
                      return (
                        <li key={index}>
                          <img src={items} alt="card1Icon" />
                        </li>
                      );
                    })}
                  </ul>
                </Box>
              </Grid>
              <Grid item md={8}>
                
               <form action={window.location.origin+'/Subscription'} className="paymentWidgets" data-brands="VISA MASTER AMEX">
                 
               </form>
               <Box className={classes.expiredActions}>
                    <Button
                      data-testid="cancelButton"
                      onClick={this.buyerSubscribe}
                      className={classes.cancelButton}
                    >
                      {this.state.pageTxtData.cancelPayment}
                    </Button>
                    </Box>

                
              </Grid>
            </Grid>
          </Box>
      );
    };

    const displayComponent = () => {
      if (this.state.showPaymentForm && this.state.checkoutId ) {
        return PaymentForm();
      } else if (this.state.userSubscribed) {
        return activeAndExpiredCard();
      } else if (this.state.showPaymentForm === false && this.state.userSubscribed === false) {
        return SubscriptionDetails();
      }
    };
    const Popup = styled(Dialog)(() => ({
      "& .MuiPaper-root": {
        height: "265px",
        width: "500px",
        borderRadius: "20px",
        border: "1px #262626 solid",
        boxShadow: "none",
        padding: "50px",
        overflow: "hidden",
        "@media (max-width:600px)": {
          height: "auto",
          width: "231px"
        }
      },
      "& .heading": {
        fontFamily: "Montserrat",
        color: "#000",
        fontStyle: "normal",
        fontSize: "40px",
        lineHeight: "24px",
        fontWeight: 700,
        margin: "20px 40px",
        textAlign: "center"
    },
      "& .MuiBackdrop-root": {
        background: "rgba(140, 140, 140, 0.60)"
      }
    }));
    let themeProvider = createTheme();
    //Customizable Area End

    return (
      <>
        <HeaderBlock />
        {/* Customizable Area Start */}
        <Box className={classes.subscribe_wrapper}>
          {displayComponent()}
        </Box>
      
        <Popup className={classes.popUp}
          open={this.state.isSubscriptionSuccess} onClose={() => {}}   maxWidth="md"
        >
          <Box style={{textAlign: 'center'}}>
            <Box className={classes.rightImageWithSuccess}>
              <ThemeProvider theme={themeProvider}>
              <div style={{
                    "height": "fit-content",
                    "display": "flex",
                    "alignItems": "center",
                    "gap": "20px",
                    textAlign: "center"
                  }}>
                    {this.getIfConditionStatement(this.state.modalTexts.success, <CheckCircleOutlineOutlinedIcon style={{
                      "fontSize": "60px",
                      "color": "#5412FC"
                    }} />, <CancelOutlinedIcon style={{
                      "fontSize": "60px",
                      "color": "red"
                    }} />)} 
                    
                    <p style={{
                      "fontSize": "36",
                      "fontWeight": 600
                    }}>{this.state.modalTexts.title}</p>
                  </div>

              </ThemeProvider>
            </Box>

            <Box className={classes.successfullySubmitted}>
              <Box>
                <p style={{
                  color: 'rgb(0, 0, 0)',
                  fontSize: '20px',
                  textAlign: 'center',
                  fontFamily: 'Montserrat, "Noto Kufi Arabic"',
                  fontWeight: 500,
                  lineHeight: '29px',
                  letterSpacing: '0px'
                }}>
                  {this.state.modalTexts.text}
                </p>
              </Box>
            </Box>
            <Button onClick={() => this.setState({isSubscriptionSuccess: false})} 
            style={{
              borderRadius: "12px",
              background: "#FFF",
              textAlign: "center",
              border: "2px solid #10BFB8",
              color: "#10BFB8",
              fontFamily: "Montserrat , Noto Kufi Arabic",
              fontSize: "16px",
              fontWeight: 600,
              padding: "12px 34px",
              textTransform: "none"
            }}
             autoFocus>
              {this.state.pageTxtData.close}
          </Button>
          </Box>
        </Popup>
        {/* Customizable Area End */}
        <FooterBlock />
      </>
    );
  }
}

export default withStyles(Styles)(BuyerSubscription);
