// Customizable Area Start
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

import AcquisitionProcessController, {
  Props,
} from "./AcquisitionProcessController.web";
import HeaderBlock from "../../../components/src/HeaderBlock.web";
import FooterBlock from "../../../components/src/FooterBlock.web";
import { arrowDownBlack, backArrow } from "./assets";
import AcquisitionProcessStepper from "./AcquisitionProcessStepper.web";
import AcquisitionProcessStepAction from "./AcquisitionProcessStepAction.web";
import { withStyles } from "@material-ui/styles";
import { conditionSort } from "../../../components/src/Helper";
// Customizable Area End

export class AcquisitionProcess extends AcquisitionProcessController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <HeaderBlock />
        <div
          className={classes.mainComponent}
          data-test-id="main-acquisition-process"
        >
          <div className={classes.backButtonDiv}>
            <button className={classes.backButtonIcon} onClick={() => {this.props.navigation.goBack()}}>
              <img src={backArrow} style={conditionSort(this.state.language == 'ar', {rotate: "180deg"}, {})} />
            </button>
            <p className={classes.backButtonText}>{this.state.pageText.back}</p>
          </div>
          <AcquisitionProcessStepper activeStep={this.state.activeStep}
          learnmoreList={this.state.learnmoreList}
          />
          <div className={classes.bellowSection}>
            <AcquisitionProcessStepAction
              data-test-id="acquisition-process-step-action"
              activeStep={this.state.activeStep}
              handleActiveStepOnChange={this.handleActiveStepOnChange}
              navigation={this.props.navigation}
            />
            <div className={classes.tipsResourcesSection}>
              <div className={classes.tipsResources}>
                <div className={classes.tipsResourcesTitle}>
                  {this.state.pageText.tips}
                </div>
                <hr />
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<img src={arrowDownBlack} />}
                    aria-controls="Shared-with-me"
                    id="Videos"
                  >
                    <div className="heading">{this.state.pageText.videos}</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.tipsResourcesVideoDiv}>
                      {this.state.tipsVideos?.map((item,index) => (
                        <div
                          key={index}
                          className={classes.tipsResourcesVideoSection}
                          onClick={()=>{this.handleFileOpen(item.link)}}
                          data-test-id={`handle-file-open-${index}`}
                        >
                          <div className={classes.tipsResourcesVideo}>
                            <img src={item.url} style={{borderRadius: "16px"}} alt="iamge of video" width="160px" height="90px" />
                          </div>
                          <div className={classes.tipsResourcesVideoText}>
                            {this.getSpecifiedLangString(this.state.language, item.title, item.arabic_title)}
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<img src={arrowDownBlack} />}
                    aria-controls="Shared-with-me"
                    id="Key-Words"
                  >
                    <div className="heading">{this.state.pageText.keywords}</div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.tipsResourcesKeyWordsDiv}>
                      {this.state.keyWords?.map((item,index) => (
                        <div
                          key={index}
                          className={classes.tipsResourcesKeyWordsSection}
                        >
                          <div className={classes.tipsResourcesKeyWords}>
                            <div className={classes.tipsResourcesKeyWordsTitle}>
                              {this.getSpecifiedLangString(this.state.language, item.title, item.arabic_title)}
                            </div>
                            <div className={classes.tipsResourcesKeyWordsText}>
                             {this.getSpecifiedLangString(this.state.language, item.description, item.arabic_description)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <FooterBlock />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles : any  = {
  mainComponent: {
    padding: "20px 100px",
    "@media (max-width:1250px)": {
      padding: "20px 30px"
    },
    "@media (max-width:768px)":{
      padding:"0 30px"
    }

  },
  backButtonDiv: {
    display: "flex",
    gap: "24px",
    alignItems: "center",
  },
  backButtonIcon: {
    background: "transparent",
    border: "none",
    padding: "0",
  },
  backButtonText: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
  },
  bellowSection: {
    display: "flex",
    justifyContent: "space-between",
    width:'100%',
    gap: "30px",
    "@media (max-width:768px)":{
      flexDirection:'column',
      justifyContent:"center",
      gap:0,
    }
  },
  tipsResourcesSection: {
    borderRadius: "20px",
    width:"32%",
    border: "1px #8C8C8C solid",
    padding: "25px 30px",
    "@media (max-width:1200px)": {
      padding: "20px 20px"
    },
    "@media (max-width:768px)":{
      width:'91.1%',
      marginTop:"20px",
      padding:'30 19px'
    },
    "& hr": {
      borderColor: "#8C8C8C",
     
    },
    "& .MuiAccordion-root": {
      boxShadow: "none",
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px",
      minHeight: "auto",
      "& *": {
        cursor: "pointer",
      },
    },
    "& .MuiIconButton-root": {
      margin: "0 !important",
      padding: "0px 6px",
    },
    "& .MuiAccordionSummary-content": {
      margin: "0",
      padding: "0px 6px",
      "& .heading": {
        color: "#262626",
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        lineHeight: "24px",
      },
    },
    "& .MuiAccordionDetails-root": {
      padding: "10px 0px",
      justifyContent: "center"
    },
  },
  tipsResources: {
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tipsResourcesTitle: {
    color: "black",
    fontSize: "20px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    lineHeight: "24px",
    marginBottom: "10px",
  },
  tipsResourcesVideoDiv: {
    display: "flex",
    gap: "24px",
    overflow: "auto",
    "@media (max-width:1200px)": {
      justifyContent: "center",
      gap: "15px",
      "& img": {
        width: "120px !important",
        height: "90px !important"
      }
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tipsResourcesVideoSection: {
    display: "grid",
    gap: "8px",
  },
  tipsResourcesVideo: {
    width: "160px",
    height: "90px",
    "@media (max-width:1200px)": {
      width: "120px !important"
    },
    borderRadius: "16px",
    border: "1px #8C8C8C solid",
  },
  tipsResourcesVideoText: {
    color: "black",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
    marginLeft: "15px",
  },
  tipsResourcesKeyWordsDiv: {
    display: "flex",
    justifyContent:"center",
    gap: "20px",
    flexGrow:1,
    overflow: "auto",
    flexWrap: 'wrap',
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tipsResourcesKeyWordsSection: {
    borderRadius: "12px",
    boxSizing:"border-box",
    padding:"15px",
    border: "1px #8C8C8C solid",
    flex: '0 0 calc(50% - 5px)', 
    maxWidth: 'calc(50% - 20px)'
    
  },
  tipsResourcesKeyWords: {
    display:"flex",
    flexDirection:"column",
    gap:"10px"
  },
  tipsResourcesKeyWordsTitle: {
    color: "#262626",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    lineHeight: "24px",
  },
  tipsResourcesKeyWordsText: {
    color: "#AAAAAA",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    lineHeight: "16px",
  },
  tipsResourcesArticlesDiv: {
    display: "grid",
    gap: "16px",
  },
  tipsResourcesArticles: {
    borderRadius: "20px",
    border: "1px #8C8C8C solid",
    padding: "14px 20px 20px",
    textAlign: "end",
  },
  tipsResourcesArticlesSection: {
    display: "grid",
    gap: "10px",
    textAlign: "left",
  },
  tipsResourcesArticlesButton: {
    background: "transparent",
    border: "none",
    padding: "0",
    color: "#262626",
    fontSize: "12px",
    fontFamily: "Montserrat",
    fontWeight: 400,
    textDecoration: "underline",
    lineHeight: "16px",
    marginTop: "10px",
  },
}

export default withStyles(styles)(AcquisitionProcess);
// Customizable Area End
