Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.mobEnText = {
  changeMob: 'Change Mobile Number',
  enterInfo: 'Please enter your information',
  afterInfo: 'Please enter verification code sent to your Mobile Number',
  enterMobNo: 'Enter mobile number',
  mobNo: 'Mobile Number',
  code: 'A code will be sent to this Mobile Number for verification',
  valid: 'Please enter a valid Mobile number',
  enterCode: 'Enter code',
  enter: "Enter",
  verify: 'Verify',
  success: 'Your Mobile Number has been changed successfully',
  confirmMessage: "Did not receive code?",
  resendCode: "Resend Code",
  successTitle: 'Sucess',
}
exports.mobArText = {
  changeMob: 'تغيير رقم الجوال',
  enterInfo: 'الرجاء إدخال المعلومات الخاصة بك',
  afterInfo: 'يرجى إدخال رمز التحقق المرسل إلى رقم هاتفك المحمول',
  enterMobNo: 'أدخل رقم الجوال',
  mobNo: 'رقم الهاتف المحمول',
  valid: 'يرجى إدخال رقم جوال صالح',
  code: 'سيتم إرسال رمز إلى رقم الهاتف المحمول هذا للتحقق',
  enterCode: 'أدخل الرمز',
  enter: "يدخل",
  verify: "تحقق",
  success: 'تم تغيير رقم هاتفك المحمول بنجاح',
  confirmMessage: "لم تتلق الرمز؟",
  resendCode: "أعد إرسال الرمز",
  successTitle: 'النجاح'
}

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putAPiMethod = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.userProfileEndpoint = "/bx_block_profile/start_ups/buyer_profile"

exports.resourceEndpoint = "bx_block_settings/resources"
exports.changeEmailEndpoint = "/bx_block_settings/settings/change_email_address"
exports.verifyOtpEmailEndpoint = "/bx_block_settings/settings/verifiy_otp"

exports.changePhoneNoEndpoint = "/bx_block_settings/settings/send_phone_otp"
exports.verifyOtpPhoneNoEndpoint = "/bx_block_settings/settings/verify_otp_change_number"

exports.verifyOldPasswordEndpoint = "/bx_block_settings/settings/verify_password"
exports.changePasswordEndpoint = "/bx_block_settings/settings/change_password"

exports.contactUsEndPoint = "/bx_block_contact_us/need_helps"
exports.getQueryEndPoint = "/bx_block_contact_us/need_helps/query_type"

exports.deactiveAccountEndPoint = "/account_block/accounts/deactivate_account"
exports.notificationSettingEndPoint="/bx_block_notifications/update_notification_setting"
exports.getNotificationSettingEndPoint="/bx_block_notifications/notification_setting"
exports.btnExampleTitle = "CLICK ME";
exports.NotificationTitle = "Notifications";
exports.arabicNotification = "إشعارات";
exports.englishLanguage = "en";
exports.SettingsTitle = "Settings";
exports.SettingsTitleArabic = "إعدادات";

exports.ResourcesTitle = "Resources";
exports.NotificationSettingTitle = "Notifications Settings";
exports.NotificationSettingTitleArabic = "إعدادات الإشعارات";
exports.ReadMoreBtn = "Read More";
exports.DeactivateAccountText = "Deactivate Account";

exports.notificationsEndpoint = "/bx_block_notifications/in_app_notifications"
exports.notificationReadAPiMethod = "PATCH";

exports.notificationsSettingList = [
  {
    id:11,
    titleMain:"SMS Notifications",
    titleMainArabic:"إشعارات الرسائل القصيرة",
    valueMain:"sms_active",
    options:[{
      id: 1,
      title:"New Messages",
      titleArabic:"رسائل جديدة",
      value:"new_message_sms",
    },
    {
      id: 2,
      title:"New Access Requests(Seller)/New Access Requests(Buyer)",
      titleArabic:"طلبات الوصول الجديدة (البائع)/طلبات الوصول الجديدة (المشتري)",
      value:"access_request_sms",
    },
    {
      id: 3,
      title:"Deal Updates",
      titleArabic:"تحديثات الصفقة",
      value:"deal_updates_sms",
    },
    {
      id: 4,
      title:"Promotions and Offers",
      titleArabic:"الترقيات والعروض",
      value:"promotions_sms",
    },]

  },
  {
    id:12,
    titleMain:"Email Notifications",
    titleMainArabic:"اشعارات البريد الالكتروني",
    valueMain:"email_active",
    options:[{
      id: 5,
      title:"New Messages",
      titleArabic:"رسائل جديدة",
      value:"new_message_email",
    },
    {
      id: 6,
      title:"New Access Requests(Seller)/New Access Requests(Buyer)",
      titleArabic:"طلبات الوصول الجديدة (البائع)/طلبات الوصول الجديدة (المشتري)",
      value:"access_request_email",
    },
    {
      id: 7,
      title:"Deal Updates",
      titleArabic:"تحديثات الصفقة",
      value:"deal_updates_email",
    },
    {
      id: 8,
      title:"Promotions and Offers",
      titleArabic:"الترقيات والعروض",
      value:"promotions_email",
    },]

  }
];

exports.enDetailsText = {
  upNext: 'Up Next',
  previous: 'Previous',
  resource: 'Resource',
  resources: 'Resources',
  readMore: 'Read More'
};
exports.arDetailsText = {
  upNext: 'التالي',
  previous: 'السابق',
  resource: 'مورد',
  resources: 'مصادر',
  readMore: 'اقرأ أكثر'
};

exports.changeEmailArabic = {
  title: "تغيير معرف البريد الإلكتروني",
  descriptionMail: "الرجاء إدخال المعلومات الخاصة بك",
  descriptionCode: "الرجاء إدخال رمز التحقق المرسل إلى عنوان بريدك الإلكتروني",
  errorMsg: "يرجى إدخال عنوان بريد إلكتروني صالح",
  emailLabel: "أدخل معرف البريد الإلكتروني *",
  emailPlaceholder: "عنوان الايميل",
  codeLabel: "ادخل الرمز *",
  codePlaceHolder: "ادخل الرمز",
  firstBlockNote: "سيتم إرسال رمز إلى عنوان البريد الإلكتروني هذا للتحقق",
  enterButton: "يدخل",
  verifyButton: "تاكيد",
  confirmMessage: "لم تتلق الرمز؟",
  resendCode: "أعد إرسال الرمز",
  successTitle: "نجاح!",
  successMessage: "لقد تم تغيير معرف البريد الإلكتروني الخاص بك بنجاح",
  settings: "إعدادات",
  backText: "السابق"
};

exports.changeEmailEnglish = {
  title: "Change Email ID",
  descriptionMail: "Please enter your information",
  descriptionCode: "Please enter verification code sent to your Email address",
  errorMsg: "Please enter a valid Email address",
  emailLabel: "Enter Email ID *",
  emailPlaceholder: "Email ID",
  codeLabel: "Enter code *",
  codePlaceHolder: "Enter code",
  firstBlockNote: "A code will be sent to this Email address for verification",
  enterButton: "Enter",
  verifyButton: "Verify",
  confirmMessage: "Did not receive code?",
  resendCode: "Resend Code",
  successTitle: "Success",
  successMessage: "Your Email ID has been changed successfully",
  settings: "Settings",
  backText: "Back"
};

exports.changePassword = "Change Password";
exports.changePasswordArabic =" تغيير كلمة المرور";
exports.pleaseEnterPassText = "Please enter a valid Password";
exports.pleaseEnterPassTextArabic = "الرجاء إدخال كلمة مرور صالحة";
exports.pleaseEnterInfoText = "Please enter your information";
exports.pleaseEnterInfoArabic = "الرجاء إدخال معلوماتك";
exports.enterCurrentPassText = "Enter Current Password ";
exports.currentPassText = "Current Password ";
exports.currentPassTextAr = "كلمة المرور الحالية ";
exports.enterCurrentPassTextArabic = "أدخل كلمة المرور الحالية ";
exports.enterNewPassText = "Enter New Password ";
exports.enterNewPassTextArabic = " أدخل كلمة مرور جديدة";
exports.reEnterNewPassText = "Re - enter New Password";
exports.reEnterNewPassTextArabic = " إعادة - إدخال كلمة مرور جديدة";
exports.enterText = "Enter";
exports.enterTextArabic = "أدخل";
exports.confirmText = "Confirm";
exports.confirmTextArabic = "تأكيد";
exports.changePassText = "Change Password";
exports.changePassTextArabic = "تغيير كلمة المرور";
exports.successText = "Success";
exports.successTextArabic = "النجاح";
exports.passSuccessText = "Your Password has been changed successfully";
exports.passSuccessTextArabic = "تم تغيير كلمة المرور الخاصة بك بنجاح";

// Customizable Area End