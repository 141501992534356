// Customizable Area Start
import React from "react";
import Box from "@material-ui/core/Box";
import {
  Typography,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  Link,
  Grid,
} from "@material-ui/core";
import {
  faceBook,
  twitterLogo,
} from "../../blocks/email-account-registration/src/assets";
const images = require("../../blocks/email-account-registration/src/assets");
const imagesfooter = require("../../blocks/email-account-registration/src/assets");
const imagesFacebook = require("../../blocks/email-account-registration/src/assets");
const imagesYoutube = require("../../blocks/email-account-registration/src/assets");
const imageTwitter = require("../../blocks/email-account-registration/src/assets");
const imageLinkedin = require("../../blocks/email-account-registration/src/assets");
import { facebookImage } from "../../blocks/social-media-account/src/assets";
import { getStorageData } from "../../framework/src/Utilities";
import TranslateText from "./GoogleTranslateAPI";
import { arabicLogo, arabicLogoBlacl } from "./assets";

interface State {
  footerText:{
  yourAcquisation:string;
  termsConditions :string;
  faqsText:string;
  newsText:string;
  privacyQues:string;
  importantLinks:string;
  financialText:string;
  usenamiSoudi:string;
  }
  lang:string;
}

let theme = createTheme();
theme = responsiveFontSizes(theme);

export default class FooterBlock extends React.Component<{},State> {
  constructor(props:{}) {
    super(props);
    this.state = {
      footerText:{
      yourAcquisation:"Your next acquisition",
      termsConditions :"Terms & Conditions",
      faqsText:"FAQs",
      newsText:"News",
      privacyQues:"Privacy Policy",
      importantLinks:"Important Links",
      financialText:"2023 © Nami Financial Platform",
      usenamiSoudi:"Usenami.com, Saudi Arabia",
      },
      lang:"en"
    };
  }
  componentDidMount(): void {
    this.getLang();
  }

  getLang = async () => {
    let lang = await getStorageData('language')
    if (lang) {
      return   this.setState({ lang: lang },  () => this.applyTranslation())
    }
    this.setState({ lang: 'en' })
  }

  applyTranslation = async () => {
    let translatedObj : any = await TranslateText(this.state.footerText, this.state.lang, 'en')
      this.setState({ footerText: translatedObj });
  }

  getSpecifiedLangString = (lang:string,stringOne:any,stringTwo:any) =>{
    if(lang ==="en"){
      return stringOne
    }else{
      return stringTwo
    }
  }
 
  render() {
    return (
      <>
        <footer>
          <Box>
            <Box style={styles.topBorder} className="topBorder" />
            <Box style={styles.logoWithSocialMedia} className="footerPadding">
              <Box
                className="logoWithh5Heading"
                style={{
                  display: "flex",
                  flexDirection: "column",
    
                }}
              >
                <img  src={ this.state.lang ==="en" ? images.namiLogo : arabicLogo} 
                style={{ maxWidth: this.state.lang === "ar" ? "25%" : "100%" }} 
                />
                <h5
                  style={{...styles.logoWithh5HeadingH5}}
                  className="logoWithh5HeadingH5"
                >
                  {this.getSpecifiedLangString(this.state.lang, this.state.footerText.yourAcquisation, "صفقتك القادمة")}
                </h5>
              </Box>

              <Box sx={styles.socialMediaLogos} className="socialMediaLogosMob">
                <a
                  href="https://instagram.com/nami.platforms?igshid=MzNlNGNkZWQ4Mg=="
                  target="_blank"
                >
                  <div
                    className="instagramStyle"
                    style={styles.socialMediaLogosImg}
                  />
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100093422689233"
                  target="_blank"
                >
                  {" "}
                  <div
                    className="facebookStyle"
                    style={styles.socialMediaLogosImg}
                  />
                </a>
                <a href="https://www.youtube.com/@nami8660" target="_blank" >
                  <div
                    className="youtubeStyle"
                    style={styles.socialMediaLogosImg}
                  />
                </a>
                <a href="https://twitter.com/NamiPlatforms" target="_blank" >
                  <div
                    className="twitterStyle"
                    style={styles.socialMediaLogosImg}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/NamiPlatforms"
                  target="_blank"
                >
                  <div
                    className="linkedInStyle"
                    style={styles.socialMediaLogosImg}
                  />
                </a>
              </Box>
            </Box>
            <Box sx={styles.footerLinksMobile} className="footerPadding footerMargin">
              <Box style={{ display: "flex" }}>
                {/* @ts-ignore */}
                <Link href="/TermsConditions"
                  style={styles.footertextLink1}
                  className="footertextLinkMobfirst"
                >
                {this.getSpecifiedLangString(this.state.lang, this.state.footerText.termsConditions, "الشروط  و الأحكام")}
                </Link>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {/* @ts-ignore */}
                <Link  href="/Faq" style={styles.footertextLink1} className="footertextLinkMob">
                 {this.getSpecifiedLangString(this.state.lang, this.state.footerText.faqsText, "الأسئلة الشائعة")}
                </Link>
                {/* @ts-ignore */}
                <Link href="/News" style={styles.footertextLink1} className="footertextLinkMob">
                {this.getSpecifiedLangString(this.state.lang, this.state.footerText.newsText, "الأخبار")}
                </Link>
              </Box>
              <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {/* @ts-ignore */}
                <Link href="/PrivacyPolicy" style={styles.footertextLink1} className="footertextLinkMob">
                {this.getSpecifiedLangString(this.state.lang, this.state.footerText.privacyQues, "سياسة الخصوصية")}
                </Link>
               
                {/* @ts-ignore */}
                <Link href="/ImportantLinks" style={styles.footertextLink1} className="footertextLinkMob">
                {this.getSpecifiedLangString(this.state.lang, this.state.footerText.importantLinks, "الروابط المهمة")}
                </Link>
              </Box>
            </Box>
            <Box sx={styles.footerLinks} className="footerPadding">
              {/* @ts-ignore */}
              <Link href="/TermsConditions"
                style={styles.footertextLink}
                className="footertextLinkMobfirst"
              >
                {this.getSpecifiedLangString(this.state.lang, this.state.footerText.termsConditions, "الشروط  و الأحكام")}
              </Link>
              {/* @ts-ignore */}
              <Link href="/Faq" style={styles.footertextLink} className="footertextLinkMob">
              {this.getSpecifiedLangString(this.state.lang, this.state.footerText.faqsText, "الأسئلة الشائعة")}
              </Link>
              {/* @ts-ignore */}
              <Link href="/News" style={styles.footertextLink} className="footertextLinkMob">
              {this.getSpecifiedLangString(this.state.lang, this.state.footerText.newsText, "الأخبار")}
              </Link>
              {/* @ts-ignore */}
              <Link href="/PrivacyPolicy" style={styles.footertextLink} className="footertextLinkMob">
              {this.getSpecifiedLangString(this.state.lang, this.state.footerText.privacyQues, "سياسة الخصوصية")}
              </Link>
              {/* @ts-ignore */}
              <Link href="/ImportantLinks" style={styles.footertextLink} className="footertextLinkMob">
              {this.getSpecifiedLangString(this.state.lang, this.state.footerText.importantLinks, "الروابط المهمة")}
              </Link>
            </Box>
            <Box style={styles.namiWithAddress} className="footerPadding blackLogoMargin">
              <Box><img
                src={ this.state.lang ==="en" ? imagesfooter.namiLogoBlack : arabicLogoBlacl}
                style={styles.namiWithAddressImg}
              /></Box>
              <Box sx={styles.namiAddress}>
                <ThemeProvider theme={theme}>
                  {/* @ts-ignore */}
                  <Typography style={styles.typo} className="namiAddressMobile">
                  {this.getSpecifiedLangString(this.state.lang, this.state.footerText.financialText, "2023 © منصة نامي المالية")}
                  </Typography>
                  {/* @ts-ignore */}
                  <Typography style={styles.typo} className="namiAddressMobile">
                   {this.getSpecifiedLangString(this.state.lang, this.state.footerText.usenamiSoudi, "المملكة العربية السعودية")}
                  </Typography>
                </ThemeProvider>
              </Box>
            </Box>
          </Box>
        </footer>
      </>
    );
  }
}
const styles: any = {
  topBorder: {
    height: "2px",
    backgroundImage:
      "linear-gradient(to right, rgba(0, 248, 162, 1), rgba(84, 18, 252, 1))",
    display: "inlineBlock",
  },
  logoWithh5Heading: {
    display: "flex",
    flexDirection: "column",
    width: "10.24%",
    "@media screen and (min-width: 500px)": {
      width: "18%"
    },
  },
  footertextLink1: {
    color: "#8C8C8C",
    fontSize: "0.9rem",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "center",
    fontFamily: "Montserrat , Sans serif",
    textDecoration: "none",
    cursor: "pointer",
    "@media screen and (min-width: 900px)": {
      fontSize: "1.2rem !important"
    },
    "@media screen and (max-width: 650px)": {
      fontSize: "12px !important"
    }
  },
  footertextLink: {
    color: "#262626",
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "20px",
    textAlign: "center",
    fontFamily: "Montserrat , Sans serif",
    textDecoration: "none",
    "@media screen and (max-width: 430px)": {
      fontSize: "14px"
    },

  },
  typo: {
    fontFamily: "Montserrat , Sans serif",
    fontSize: "12px",
    fontWeight: 400,
    margin: "0px",
    padding: "0px",
    fontStyle: "normal",
    lineHeight: "16px",
  },
  footerLinks: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "#8C8C8C",
    gap: "15px",
    flexWrap: "wrap",
    margin: "0 2.86%",
    marginTop: "24px",
    "@media screen and (max-width: 900px)": {
      display: "none",
      padding: "0px 15px",
      marginBottom: "50px",
      marginTop: "85px",
    },
  },
  footerLinksMobile: {
    display: 'none',
    padding: "0px 15px",
    color: "#8C8C8C",
    marginTop: "24px",
    gap: "16px",
    "@media screen and (max-width: 900px)": {
      display: "flex",
      flexDirection: "column",
      padding: "0px 15px",
      marginBottom: "50px",
      marginTop: "85px",
    },
  },
  logoWithh5HeadingH5: {
    color: "#5412FC",
    fontWeight: 700,
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "20px",
    marginTop: "0px",
    "@media screen and (max-width: 700px)": {
      fontSize: "7px"
    },
  },
  socialMediaLogos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap:"28px",
    "@media screen and (max-width: 700px)": {
      gap:"14px",

    },
  },

  logoWithSocialMedia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "1%"
  },
  socialMediaLogosImg: {
    width: "40px",
    height: "40px",
    "&: hover": {
      fill: "red",
    },
    "@media screen and (max-width: 600px)": {
      width: "18px !important",
      height: "18px !important"
    }
  },

  namiWithAddressImg: {
    width: "70.6px",
    height: "30px",
  },
  namiWithAddress: {
    display: "flex",
    flexDirection: "row",

    width: "83%",
    margin: "1% 2.86%",
  },
  namiAddress: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginLeft: "15px",
  }
};
// Customizable Area End
