export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");

export const backgroundArrow1 = require("../assets/arrow1.svg");
export const backgroundArrow = require("../assets/arrow2.svg");
export const backgroundArrow3 = require("../assets/arrow3.svg");

export const infoCircle = require("../assets/akar-icons_circle-alert.svg");
export const chatIcon = require("../assets/majesticons_chats-2-line.svg");
export const doorEnter = require("../assets/majesticons_door-enter-line.svg");
export const lifebuoy = require("../assets/majesticons_lifebuoy-line.svg");
export const users = require("../assets/majesticons_users-line.svg");
export const activeIcon = require("../assets/active_icon.svg");
export const alertIcon = require("../assets/alert_icon.svg");
export const expiredIcon = require("../assets/expired_icon.svg");
export const card1Icon = require("../assets/card1.svg");
export const card2Icon = require("../assets/card2.svg");
export const card3Icon = require("../assets/card3.svg");
export const card4Icon = require("../assets/card4.svg");
export const card5Icon = require("../assets/card5.svg");
export const card6Icon = require("../assets/card6.svg");
export const card7Icon = require("../assets/card7.svg");
export const card8Icon = require("../assets/card8.svg");

export const feature = [{
    image: doorEnter,
    title: "Exclusive Access:",
    details: "Engage with companies boasting up to SAR 1 Million in revenue."
 },
 {
    image: infoCircle,
    title: "Priority Notifications:",
    details: "Engage with companies boasting up to SAR 1 Million in revenue."
 },
 {
    image: chatIcon,
    title: "Dedicated connect:",
    details: "Engage with companies boasting up to SAR 1 Million in revenue."
 },
 {
    image: lifebuoy,
    title: "Dedicated Support:",
    details: "Engage with companies boasting up to SAR 1 Million in revenue."
 },
 {
    image: users,
    title: "Networking:",
    details: "Engage with companies boasting up to SAR 1 Million in revenue."
 }]